export const determineAthleteSortOrder = (sortColumn) => {
  const { path, order } = sortColumn;
  let sort = null;
  switch (path) {
    case "firstName":
      sort = "FirstName";
      break;
    case "middleName":
      sort = "MiddleName";
      break;
    case "lastName":
      sort = "LastName";
      break;
  }
  if (order === "desc") {
    sort = sort + "Desc";
  }
  return sort;
};

export const determineResultSortOrder = (sortColumn) => {
  const { path, order } = sortColumn;
  let sort = null;
  switch (path) {
    case "formattedDate":
      sort = "Date";
      break;
    case "firstName":
      sort = "FirstName";
      break;
    case "middleName":
      sort = "MiddleName";
      break;
    case "lastName":
      sort = "LastName";
      break;
    case "event":
      sort = "Event";
      break;
    case "result":
      sort = "Result";
      break;
  }
  if (order === "desc") {
    sort = sort + "Desc";
  }
  return sort;
};

export const determineReportSortOrder = (sortColumn) => {
  const { path, order } = sortColumn;
  let sort = null;
  switch (path) {
    case "formattedTestingDate":
      sort = "TestingDate";
      break;
    case "firstName":
      sort = "FirstName";
      break;
    case "middleName":
      sort = "MiddleName";
      break;
    case "lastName":
      sort = "LastName";
      break;
    case "overallIndex":
      sort = "OverallIndex";
      break;
  }
  if (order === "desc") {
    sort = sort + "Desc";
  }
  return sort;
};

export const determineTeamReportSortOrder = (sortColumn) => {
  const { path, order } = sortColumn;
  let sort = null;
  switch (path) {
    case "formattedDate":
      sort = "Date";
      break;
    case "rosterName":
      sort = "RosterName";
      break;
    case "averageEpicIndex":
      sort = "OverallIndex";
      break;
  }
  if (order === "desc") {
    sort = sort + "Desc";
  }
  return sort;
};

