import React from "react";
import { decimalNumberPattern } from "../../../../../common/patterns/patterns";
import Event from "./Event";

const fields = [
  { type: "input", label: "Final time", property: "finalTime" },
];

const validations = {
  finalTime: {
    pattern: {
      value: decimalNumberPattern,
      message: "Final time needs to be a number",
    },
  },
};

const initialValues = {
  finalTime: "0.0"
}

const isValidLDrill = (values) => {
  const finalTime = parseFloat(values.finalTime);

  if (finalTime === 0) {
    return {
      isValid: false,
      message: "Final time cannot be 0",
      eventFields: null,
    };
  }

  const eventFields = {
    type: "LDrill",
    finalTime,
  };
  return { isValid: true, message: null, eventFields };
};


const LDrill = ({ athleteId, isMetric }) => {
  return (
    <Event
    title={"Add L Drill"}
    athleteId={athleteId}
    isMetric={isMetric}
    fields={fields}
    initialValues={initialValues}
    validations={validations}
    isValidEvent={isValidLDrill}
  />
   
  );
};

export default LDrill;
