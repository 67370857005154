import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

const Button = ({
  className,
  type,
  children,
  onClick,
  color,
  fullWidth,
  disabled,
  href,
}) => {
  let classes = fullWidth
    ? classNames(className, styles.button, styles.buttonFullWidth)
    : classNames(className, styles.button);

  if (type === "back") {
    classes = classNames(classes, styles.backButton);
  } else if (type === "delete") {
    classes = classNames(classes, styles.deleteButton);
  }

  if (href) {
    return (
      <a className={classes} href={href} onClick={onClick} tabIndex="0" disabled={disabled}>
        {children}
      </a>
    );
  }

  return (
    <button className={classes} onClick={onClick} tabIndex="0" disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
