import React, { useContext } from "react";
import Detail from "../../../../components/detail/Detail";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import Button from "../../../../components/button/Button";
import styles from "./AthleteDetail.module.scss";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import { AppContext } from "../../../../contexts/AppContext";
import { getAthleteFields } from "../athleteUtils";

const AthleteDetail = ({ athlete }) => {
  const { replaceModal, hideModal } = useContext(ModalContext);
  const { athletesState } = useContext(AthletesContext);
  const { appState } = useContext(AppContext);
  const { metricSetting } = appState;
  const athleteFields = getAthleteFields(metricSetting);
  
  const gotoDeleteAthlete = () => {
    const { id, firstName, lastName } = athlete;
    const { activeRoster } = athletesState;
    const roster = activeRoster === -1 ? null : activeRoster;
    replaceModal("DeleteAthlete", { id, firstName, lastName, roster });
  };

  const gotoEditAthlete = () => {
    replaceModal("EditAthlete", { athlete });
  };

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Athlete Detail</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          {athleteFields.map((athleteDetailField) => {
            const { label, property } = athleteDetailField;
            return (
              <Detail key={label} label={label} value={athlete[property]} />
            );
          })}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button onClick={gotoEditAthlete}>Edit Athlete</Button>
            <Button onClick={gotoDeleteAthlete} type="delete">Delete Athlete</Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default AthleteDetail;
