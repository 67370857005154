import React, { useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import Button from "../../../../components/button/Button";
import { ResultsContext } from "../../../../contexts/ResultsContext";
import { ModalContext } from "../../../../contexts/ModalContext";
import { exportResultsToExcel } from "../../../../export/results/exportResultsExcel";
import styles from "./ExportResult.module.scss";
import { exportToCsv } from "../../../../export/exportCsv";
import { exportResultsToCsv } from "../../../../export/results/exportResultsCsv";
import { getExportedEvents } from "../../../../services/resultsService";
import { AuthContext } from "../../../../contexts/AuthContext";

const EXCEL = 1;
const CSV = 2;

const ExportResult = () => {
  const { resultsState } = useContext(ResultsContext);
  const { hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const {
    currentPage,
    resultsPerPage,
    searchQuery,
    startDateFilter,
    endDateFilter,
    activeRosterFilter,
    sort,
  } = resultsState;
  const { userId } = authState;

  const exportResults = async (type) => {
    const results = await getExportedEvents(
      userId,
      currentPage,
      resultsPerPage,
      activeRosterFilter,
      searchQuery,
      sort,
      startDateFilter,
      endDateFilter
    );
    if (type === EXCEL) {
      exportResultsToExcel(results)
    } else {
      exportResultsToCsv(results)
    }
  };

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Export Results</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.centeredWrapper}>
            <Button onClick={() => exportResults(EXCEL)}>
              Export Results (.xls)
            </Button>
          </div>
          {/* <div className={styles.centeredWrapper}>
            <Button onClick={() => exportResults(CSV)}>
              Export Results (.csv)
            </Button>
          </div> */}
        </ModalCardBody>
        <ModalCardFooter />
      </ModalCard>
    </Modal>
  );
};

export default ExportResult;
