import React from "react";

import styles from "./Detail.module.scss";

const Detail = ({ label, value, unit }) => {
  if (unit) {
    label = `${label} (${unit})`;
  }
  return (
    <div className={styles.detailWrapper}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value ? value : "-"}</div>
    </div>
  );
};

export default Detail;
