import React from "react";
import styles from "./ProgressMessage.module.scss";
import classNames from "classnames";
import { ClipLoader } from "react-spinners";

const ProgressMessage = ({message, isError, isLoading}) => {
  const loader = isLoading ? (
    <ClipLoader
      css={"margin-left 10px;"}
      sizeUnit={"px"}
      size={20}
      color={"white"}
      loading={isLoading}
    />
  ) : null;

  const classes = isError
    ? classNames(styles.progressMessage, styles.progressMessageIsError)
    : styles.progressMessage;

  return (
    <div className={classes}>
      {message}
      {loader}
    </div>
  );
};

export default ProgressMessage;
