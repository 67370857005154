import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./DeleteRoster.module.scss";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { ModalCardFooter } from "bloomer/lib/components/Modal/Card/ModalCardFooter";
import Button from "../../../../components/button/Button";
import { deleteRoster } from "../../../../services/rosterService";
import { AuthContext } from "../../../../contexts/AuthContext";
import { AthletesContext } from "../../../../contexts/AthletesContext";

const DeleteRoster = ({ roster }) => {
  const [progressMessage, setProgressMessage] = useState(null);
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [backButtonLabel, setBackButtonLabel] = useState("Cancel");
  const { hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch } = useContext(AthletesContext);

  const handleDeleteRoster = async () => {
    try {
      setProgressMessage({
        isLoading: true,
        message: "Deleting Roster",
        isError: false,
      });
      const { userId } = authState;
      await deleteRoster(userId, roster.id);
      athletesDispatch({ type: "DELETE_ROSTER", payload: roster.id });
      setProgressMessage({
        isLoading: false,
        message: "Sucessfully Deleted Roster",
        isError: false,
      });
      setDeleteButtonDisabled(true);
      setBackButtonLabel("Exit");
    } catch (err) {
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed deleting roster, please try again",
            isError: true,
          });
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Delete Roster</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.deleteMessage}>
            Are you sure you want to delete{" "}
            <span className={styles.rosterName}>{roster.name}</span>?
          </div>
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              {backButtonLabel}
            </Button>
            <Button
              type={"delete"}
              onClick={handleDeleteRoster}
              disabled={isDeleteButtonDisabled}
            >
              Delete
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default DeleteRoster;
