import React, { useContext } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import AthleteDetail from "../modals/athlete/athletedetail/AthleteDetail";
import DeleteAthlete from "../modals/athlete/deleteathlete/DeleteAthlete";
import SelectReportAthlete from "../modals/report/selectreportathlete/SelectReportAthlete";
import EditAthlete from "../modals/athlete/editathlete/EditAthlete";
import AddAthlete from "../modals/athlete/addathlete/AddAthlete";
import AddExistingAthlete from "../modals/athlete/addexistingathlete/AddExistingAthlete";
import AddRoster from "../modals/roster/addroster/AddRoster";
import DeleteRoster from "../modals/roster/deleteroster/DeleteRoster";
import AddRosterAthlete from "../modals/roster/addrosterathlete/AddRosterAthlete";
import UploadAthletes from "../modals/athlete/uploadathletes/UploadAthletes";
import ResultDetail from "../modals/result/resultdetail/ResultDetail";
import DeleteResult from "../modals/result/deleteresult/DeleteResult";
import ExportResult from "../modals/result/exportresult/ExportResult";
import AddEpicIndexReport from "../modals/report/AddEpicIndexReport/AddEpicIndexReport";
import EpicIndexReportDetail from "../modals/report/EpicIndexReportDetail/EpicIndexReportDetail";
import DeleteEpicIndexReport from "../modals/report/DeleteEpicIndexReport/DeleteEpicIndexReport";
import AddTeamReport from "../modals/report/AddTeamReport/AddTeamReport";
import EditAccount from "../modals/account/editaccount/EditAccount";
import ThirdPartyConfirm from "../modals/account/thirdpartyconfirm/ThirdPartyConfirm";
import SelectAthlete from "../modals/result/addresult/selectathlete/SelectAthlete";
import SelectEvent from "../modals/result/addresult/selectevent/SelectEvent";
import ProAgility from '../modals/result/addresult/events/ProAgility'
import Flying from '../modals/result/addresult/events/Flying'
import Vertical from '../modals/result/addresult/events/Vertical'
import LDrill from '../modals/result/addresult/events/LDrill'
import Lap from '../modals/result/addresult/events/Lap'
import ApproachVertical from '../modals/result/addresult/events/ApproachVertical'
import ReactShuttle from '../modals/result/addresult/events/ReactShuttle'
import BroadJump from '../modals/result/addresult/events/BroadJump'
import ReactAgility from '../modals/result/addresult/events/ReactAgility'
import _505AgilityTest from '../modals/result/addresult/events/_505AgilityTest'
import TTest from '../modals/result/addresult/events/TTest'
import Lifting from '../modals/result/addresult/events/Lifting'
import Dash from '../modals/result/addresult/events/Dash'
import DeleteDataModal from '../modals/deletedatamodal/DeleteDataModal'
import SelectSplits from "../modals/result/addresult/selectsplits/SelectSplits";
import UploadResult from './result/uploadresult/UploadResult'

const lookup = {
  AthleteDetail,
  AddExistingAthlete,
  DeleteAthlete,
  SelectReportAthlete,
  EditAthlete,
  AddAthlete,
  AddRoster,
  DeleteRoster,
  AddRosterAthlete,
  UploadAthletes,
  ResultDetail,
  DeleteResult,
  AddEpicIndexReport,
  EpicIndexReportDetail,
  DeleteEpicIndexReport,
  AddTeamReport,
  EditAccount,
  ThirdPartyConfirm,
  SelectAthlete,
  SelectEvent,
  ProAgility,
  Flying,
  ApproachVertical,
  BroadJump,
  Vertical,
  LDrill,
  ReactAgility,
  ReactShuttle,
  Lap,
  _505AgilityTest,
  Lifting,
  TTest,
  ExportResult,
  DeleteDataModal,
  SelectSplits,
  Dash,
  UploadResult
};

const ModalManager = () => {
  const { modals } = useContext(ModalContext);

  const renderedModals = modals.map((modal, index) => {
    const { type, props } = modal;
    const ModalComponent = lookup[type];
    if (ModalComponent) {

    }
    return <ModalComponent {...props} key={type + index} />;
  });
  return renderedModals;
};

export default ModalManager;
