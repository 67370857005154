import React, { useReducer, createContext } from "react";

const initialState = {
  isAuthenticated: null,
  userId: null,
  username: null
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "AUTH_USER":
      const userId = action.payload;
      return { ...state, isAuthenticated: true, userId };
    case "UNAUTH_USER":
      return {...state, isAuthenticated: false, userId: null, username: null}
    case "CONFIRM_USER":
      const username = action.payload;
      return { ...state,  username };
    default:
      return state;
  }
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
