import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import ModalManager from "./containers/modals/ModalManager";
import { ModalProvider } from "./contexts/ModalContext";
import { AthletesProvider } from "./contexts/AthletesContext";
import { ResultsProvider } from "./contexts/ResultsContext";
import { ReportsProvider } from "./contexts/ReportsContext";
import { AccountProvider } from "./contexts/AccountContext";
import { AppProvider } from "./contexts/AppContext";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ModalProvider>
        <AuthProvider>
          <AppProvider>
            <AccountProvider>
              <ResultsProvider>
                <ReportsProvider>
                  <AthletesProvider>
                    <ModalManager />
                    <App />
                  </AthletesProvider>
                </ReportsProvider>
              </ResultsProvider>
            </AccountProvider>
          </AppProvider>
        </AuthProvider>
      </ModalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
