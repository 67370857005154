import React from "react";
import { ClipLoader } from "react-spinners";

import styles from "./FetchMessage.module.scss";

const FetchMessage = ({ message }) => {
  return (
    <div className={styles.fetchContainer}>
      {message}
      <ClipLoader
        css={"margin-left 10px;"}
        sizeUnit={"px"}
        size={20}
        color={"#4a4a4a"}
        loading={true}
      />
    </div>
  );
};

export default FetchMessage;
