import { saveAs } from "file-saver";

export const exportToCsv = (data, fileName) => {
  const jsonData = JSON.stringify(data);

  var array = typeof objArray !== "object" ? JSON.parse(jsonData) : jsonData;
  var csv = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index];
    }

    csv += line + "\r\n";
  }

  saveAs(
    new Blob([csv], { type: "application/octet-stream" }),
    `${fileName}.csv`
  );
};
