import React, { useContext } from "react";
import { ModalContext } from "../../../contexts/ModalContext";
import Switch from "react-switch";
import styles from "./ThirdPartyIntegration.module.scss";
import { AccountContext } from "../../../contexts/AccountContext";

const ThirdPartyIntegration = () => {
  const { showModal } = useContext(ModalContext);
  const { accountInfo } = useContext(AccountContext);
  const { thirdPartyIntegration } = accountInfo;

  return (
    <>
      <div className={styles.settingsContainer}>
        <div className={styles.titleDescriptionContainer}>
          <div className={styles.title}>Thirdparty Integration</div>
          <div className={styles.description}>
            Enables thirdparty companies to view athlete and results data
          </div>
        </div>
      </div>
      {thirdPartyIntegration.map((company) => {
        return (
          <div className={styles.thirdPartyContainer}>
            <div className={styles.companyName}>{company.name}</div>
            <Switch
              id={company.id}
              onChange={(checked, e, id) =>
                showModal("ThirdPartyConfirm", { enable: checked, company })
              }
              checked={company.isIntegrated}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={"#1d88f2"}
            />
          </div>
        );
      })}
    </>
  );
};

export default ThirdPartyIntegration;
