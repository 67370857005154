import React, { createContext, useReducer } from "react";
import { determineResultSortOrder } from "../utilities/sortOrder";

const initialState = {
  currentPage: 1,
  resultsPerPage: 20,
  totalResults: null,
  searchQuery: null,
  results: null,
  sortOrder: null,
  startDateFilter: null,
  endDateFilter: null,
  activeRosterFilter: -1,
  sortColumn: { path: "formattedDate", order: "desc" },
  sort: "DateDesc",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIAL_STATE": {
      return initialState;
    }
    case "ADD_RESULT": {
      const { results } = state;
      const newResult = action.payload;
      const newResults = [...results, newResult];
      return {
        ...state,
        results: newResults,
      };
    }
    case "SET_RESULTS": {
      const { results, totalResults } = action.payload;
      return {
        ...state,
        results,
        totalResults,
      };
    }
    case "ADD_RESULT": {
      const result = action.payload;
      const results = [...state.results, result];
      return { ...state, results };
    }
    case "DELETE_RESULT": {
      const resultId = action.payload;
      const results = state.results.filter((result) => result.id !== resultId);
      return { ...state, results };
    }
    case "SET_SORT_COLUMN": {
      const sortColumn = action.payload;
      console.log('hello')
      console.log(sortColumn)
      const sort = determineResultSortOrder(sortColumn);
      return { ...state, sortColumn, sort };
    }
    case "SET_START_DATE_FILTER": {
      const startDateFilter = action.payload;
      return { ...state, startDateFilter };
    }
    case "SET_END_DATE_FILTER": {
      const endDateFilter = action.payload;
      return { ...state, endDateFilter };
    }
    case "SET_ACTIVE_ROSTER_FILTER": {
      const activeRosterFilter = action.payload;
      return { ...state, activeRosterFilter };
    }
    case "CHANGE_CURRENT_PAGE":
      const pageNumber = action.payload;
      return { ...state, currentPage: pageNumber };
    case "CHANGE_RESULTS_PER_PAGE":
      const resultsPerPage = action.payload;
      return { ...state, resultsPerPage };
    case "SET_SEARCH_QUERY":
      const searchQuery = action.payload;
      return { ...state, searchQuery };
    case "SET_FILTER":
      const { startDateFilter, endDateFilter, activeRosterFilter } =
        action.payload;
      return { ...state, startDateFilter, endDateFilter, activeRosterFilter };
    default:
      return state;
  }
};

const ResultsContext = createContext(initialState);

const ResultsProvider = ({ children }) => {
  const [resultsState, resultsDispatch] = useReducer(reducer, initialState);

  return (
    <ResultsContext.Provider
      value={{
        resultsState,
        resultsDispatch,
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
};

export { ResultsContext, ResultsProvider };
