import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import Button from "../../components/button/Button";
import FetchMessage from "../../components/fetchMessage/FetchMessage";
import SearchBar from "../../components/searchbar/SearchBar";
import Table from "../../components/table/Table";
import { Tabs, TabLink, Tab } from "bloomer";
import { AuthContext } from "../../contexts/AuthContext";
import { ModalContext } from "../../contexts/ModalContext";
import { ReportsContext } from "../../contexts/ReportsContext";
import {
  getExportedReports,
  getExportedTeamReports,
  getPaginatedReports,
  getPaginatedTeamReports,
} from "../../services/reportsService";
import Select from "../../components/select/Select";
import { AppContext } from "../../contexts/AppContext";
import {
  exportIndexReports,
  exportTeamReports,
} from "../../export/exportReports";
import { AccountContext } from "../../contexts/AccountContext";
import { AthletesContext } from "../../contexts/AthletesContext";
import { getRosters } from "../../services/rosterService";
import styles from "./Reports.module.scss";
import moment from "moment";
import PaginatedTable from "../../containers/paginatedtable/PaginatedTable";

const tableColumns = [
  { label: "Testing Date", path: "formattedTestingDate" },
  { label: "First Name", path: "firstName" },
  { label: "Middle Name", path: "middleName" },
  { label: "Last Name", path: "lastName" },
  { label: "Overall Index", path: "overallIndex" },
];

const teamReportTableColumns = [
  { label: "Date", path: "formattedDate" },
  { label: "Roster Name", path: "rosterName" },
  { label: "Average Epic Index", path: "averageEpicIndex" },
];

const TEAM_ACTIVE_REPORT_TYPE = 2;
const INDIVIDUAL_ACTIVE_REPORT_TYPE = 1;

const Reports = () => {
  const { state: authState } = useContext(AuthContext);
  const { reportsState, reportsDispatch } = useContext(ReportsContext);
  const { showModal } = useContext(ModalContext);
  const { appDispatch } = useContext(AppContext);
  const { accountInfo } = useContext(AccountContext);
  const { athletesState, athletesDispatch } = useContext(AthletesContext);
  const { userId } = authState;
  const { rosters } = athletesState;

  const {
    currentPage,
    reportsPerPage,
    searchQuery,
    totalReports,
    reports,
    sort,
    activeReportType,
    reportTypes,
    sortColumn,
    activeRosterFilter,
    startDateFilter,
    endDateFilter,
  } = reportsState;
  const { reportSpots } = accountInfo;

  const isTeamReport = activeReportType === TEAM_ACTIVE_REPORT_TYPE;

  useEffect(() => {
    appDispatch({ type: "SET_CURRENT_PAGE_TITLE", payload: "Results" });
  }, [appDispatch]);

  useEffect(() => {
    if (!rosters) {
      const fetchRosters = async () => {
        const rosters = await getRosters(userId);
        athletesDispatch({ type: "FETCH_ROSTERS", payload: rosters });
      };
      fetchRosters();
    }
  }, [athletesDispatch, userId, rosters]);

  useEffect(() => {
    const fetchReports = async () => {
      if (activeReportType === INDIVIDUAL_ACTIVE_REPORT_TYPE) {
        const paginatedReportData = await getPaginatedReports(
          userId,
          currentPage,
          reportsPerPage,
          activeRosterFilter,
          searchQuery,
          sort,
          startDateFilter,
          endDateFilter
        );
        const { reports, totalReports } = paginatedReportData;
        reportsDispatch({
          type: "SET_REPORTS",
          payload: { reports, totalReports },
        });
      } else {
        const paginatedTeamReportData = await getPaginatedTeamReports(
          userId,
          currentPage,
          reportsPerPage,
          activeRosterFilter,
          searchQuery,
          sort,
          startDateFilter,
          endDateFilter
        );

        const { reports, totalReports } = paginatedTeamReportData;
        reportsDispatch({
          type: "SET_REPORTS",
          payload: { reports, totalReports },
        });
      }
    };
    fetchReports();
  }, [
    activeReportType,
    reportsDispatch,
    currentPage,
    userId,
    reportsPerPage,
    searchQuery,
    sort,
    activeRosterFilter,
    startDateFilter,
    endDateFilter,
  ]);

  const handleReportsPerPage = (currentPage, reportsPerPage) => {
    reportsDispatch({
      type: "CHANGE_REPORTS_PER_PAGE",
      payload: reportsPerPage,
    });
  };

  const handlePageChange = (page) => {
    reportsDispatch({ type: "CHANGE_CURRENT_PAGE", payload: page });
  };

  const filterReports = async () => {
    const startDateUtc = moment(startDateFilter).startOf("day").toISOString();
    const endDateUtc = moment(endDateFilter).endOf("day").toISOString();
    if (activeReportType === INDIVIDUAL_ACTIVE_REPORT_TYPE) {
      const paginatedReportData = await getPaginatedReports(
        userId,
        currentPage,
        reportsPerPage,
        activeRosterFilter,
        searchQuery,
        sort,
        startDateUtc,
        endDateUtc
      );
      const { reports, totalReports } = paginatedReportData;
      reportsDispatch({
        type: "SET_REPORTS",
        payload: { reports, totalReports },
      });
    } else {
      const paginatedTeamReportData = await getPaginatedTeamReports(
        userId,
        currentPage,
        reportsPerPage,
        activeRosterFilter,
        searchQuery,
        sort,
        startDateUtc,
        endDateUtc
      );

      const { reports, totalReports } = paginatedTeamReportData;
      reportsDispatch({
        type: "SET_REPORTS",
        payload: { reports, totalReports },
      });
    }
  };

  if (!reports || !rosters) {
    return <FetchMessage message={"Fetching Reports"} />;
  }

  return (
    <>
      <div
        className={styles.reportSpotsContainer}
      >{`Report Spots Available: ${reportSpots}`}</div>
      <div className={styles.actionContainer}>
        <SearchBar
          query={searchQuery}
          onChange={(query) =>
            reportsDispatch({ type: "SET_SEARCH_QUERY", payload: query })
          }
          onSearch={(query) =>
            reportsDispatch({ type: "SET_SEARCH_QUERY", payload: query })
          }
        />
        <Button
          className={styles.pushRight}
          onClick={() => {
            if (activeReportType === 2) {
              showModal("AddTeamReport");
            } else {
              showModal("SelectReportAthlete");
            }
          }}
        >
          + Reports
        </Button>
        <Button
          onClick={async () => {
            const startDateUtc = moment(startDateFilter)
              .startOf("day")
              .toISOString();
            const endDateUtc = moment(endDateFilter).endOf("day").toISOString();
            if (activeReportType === INDIVIDUAL_ACTIVE_REPORT_TYPE) {
              const reports = await getExportedReports(
                userId,
                currentPage,
                reportsPerPage,
                activeRosterFilter,
                searchQuery,
                sort,
                startDateUtc,
                endDateUtc
              );
              exportIndexReports(reports);
            } else {
              const reports = await getExportedTeamReports(
                userId,
                currentPage,
                reportsPerPage,
                activeRosterFilter,
                searchQuery,
                sort,
                startDateUtc,
                endDateUtc
              );
              exportTeamReports(reports);
            }
          }}
        >
          Export Reports
        </Button>
      </div>
      <div className={styles.datePickerContainer}>
        <div className={styles.datePicker}>
          <DatePicker
            placeholderText={"Enter Start Date"}
            selected={startDateFilter}
            onChange={(startDate) =>
              reportsDispatch({
                type: "SET_START_DATE_FILTER",
                payload: startDate,
              })
            }
            selectsStart
            startDate={startDateFilter}
            endDate={endDateFilter}
            isClearable
          />
        </div>
        <div className={styles.datePicker}>
          <DatePicker
            placeholderText={"Enter End Date"}
            selected={endDateFilter}
            onChange={(endDate) =>
              reportsDispatch({ type: "SET_END_DATE_FILTER", payload: endDate })
            }
            selectsEnd
            startDate={startDateFilter}
            endDate={endDateFilter}
            minDate={startDateFilter}
            isClearable
          />
        </div>
        <Select
          options={rosters}
          value={activeRosterFilter}
          onOptionsChange={(roster) =>
            reportsDispatch({
              type: "SET_ACTIVE_ROSTER_FILTER",
              payload: parseInt(roster),
            })
          }
        />
        <Button className={styles.filterButton} onClick={filterReports}>
          Filter
        </Button>
      </div>
      <Tabs isBoxed>
        <ul>
          {reportTypes.map((reportType) => {
            const isActive = reportType.id === activeReportType;
            return (
              <Tab isActive={isActive} key={reportType.id}>
                <TabLink
                  onClick={() =>
                    reportsDispatch({
                      type: "CHANGE_REPORT_TYPE",
                      payload: parseInt(reportType.id),
                    })
                  }
                >
                  {reportType.name}
                </TabLink>
              </Tab>
            );
          })}
        </ul>
      </Tabs>
      <PaginatedTable
        columns={isTeamReport ? teamReportTableColumns : tableColumns}
        data={reports}
        sortColumn={sortColumn}
        onSort={(sortColumn) => {
          reportsDispatch({
            type: "SET_SORT_COLUMN",
            payload: sortColumn,
          });
        }}
        total={totalReports}
        pageSize={reportsPerPage}
        onRowClick={(report) => showModal("EpicIndexReportDetail", { report })}
        onShowSizeChange={handleReportsPerPage}
        onPageChange={handlePageChange}
        onGotoChange={handlePageChange}
      />
    </>
  );
};

export default Reports;
