import moment from "moment";
import { exportToExcelSpreadsheet } from "./exportExcel";

export const exportAthletes = async (athletes, rosterName) => {
  const fileName = rosterName ? `${rosterName} athletes` : "athletes";
  const currentTime = moment().format("MM/DD/YYY h:mma").toString();
  const athletesDeepCopy = athletes.map((a) => Object.assign({}, a));
  athletesDeepCopy.forEach((athlete) => {
    delete athlete.id;
    delete athlete.coachID;
  });
  exportToExcelSpreadsheet(athletesDeepCopy, `${fileName} - ${currentTime}`);
};
