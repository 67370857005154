import React from "react";
import Event from "./Event";
import { decimalNumberPattern } from "../../../../../common/patterns/patterns";

const LEFT = 1;

const directionOptions = [
  { id: 1, name: "Left" },
  { id: 2, name: "Right" },
];

const fields = [
  { type: "input", label: "Split Time", property: "splitTime" },
  { type: "input", label: "Final Time", property: "finalTime" },
  { type: "input", label: "Reaction Time", property: "reactionTime" },
  {
    type: "select",
    label: "Direction",
    property: "direction",
    options: directionOptions,
  },
];

const validations = {
  splitTime: {
    pattern: {
      value: decimalNumberPattern,
      message: "Split time needs to be a number",
    },
  },
  finalTime: {
    pattern: {
      value: decimalNumberPattern,
      message: "Final time needs to be a number",
    },
  },
  reactionTime: {
    pattern: {
      value: decimalNumberPattern,
      message: "Reaction time needs to be a number",
    },
  },
  direction: null
};

const intialValues = { splitTime: "0.0", finalTime: "0.0", reactionTime: "0.0", direction: "1" };

const isValidReactShuttle = (values) => {
  const splitTime = parseFloat(values.splitTime);
  const finalTime = parseFloat(values.finalTime);
  const reactionTime = parseFloat(values.reactionTime)
  const direction = parseInt(values.direction);

  if (splitTime === 0 || finalTime === 0 || reactionTime == 0) {
    return {
      isValid: false,
      message: "Split/reaction/final time cannot be 0",
      eventFields: null,
    };
  }

  if (splitTime >= finalTime) {
    return {
      isValid: false,
      message: "Final time cannot equal or be smaller than split time",
      eventFields: null,
    };
  }

  const eventFields = {
    type: "ReactShuttle",
    splitTime,
    finalTime,
    reactionTime,
    isLeft: direction == LEFT ? true : false
  };
  return { isValid: true, message: null, eventFields };
};

const ReactShuttle = ({ athleteId, isMetric }) => {
  return (
    <Event
      title={"Add React Shuttle"}
      athleteId={athleteId}
      isMetric={isMetric}
      fields={fields}
      intialValues={intialValues}
      validations={validations}
      isValidEvent={isValidReactShuttle}
    />
  );
};

export default ReactShuttle;
