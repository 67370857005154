import axios from "axios";
import environment from "../environment/environment";

const { API_URL } = environment;

const formatIndividualReports = (reports) => {
  reports.forEach((report) => {
    report.tenYardDashTime = report.tenYardDashTime.toFixed(2)
    report.proAgilityTime = report.proAgilityTime.toFixed(2)
    report.verticalTime = report.verticalTime.toFixed(2)
    report.dashIndex = report.dashIndex.toFixed(0);
    report.proAgilityIndex = report.proAgilityIndex.toFixed(0)
    report.verticalIndex = report.verticalIndex.toFixed(0)
    report.overallIndex = report.overallIndex.toFixed(0)
    report.formattedTestingDate = new Date(report.testingDate).toDateString();
  });

}

const formatTeamReports = (reports) => {
  reports.forEach((report) => {
    report.averageDashIndex = report.averageDashIndex.toFixed(0)
    report.averageVerticalIndex = report.averageVerticalIndex.toFixed(0)
    report.averageProAgilityIndex = report.averageProAgilityIndex.toFixed(0)
    report.averageEpicIndex = report.averageEpicIndex.toFixed(0)
    report.formattedDate = new Date(report.date).toDateString();

  });

}

export const getPaginatedTeamReports = async (
  userId,
  pageNumber,
  reportsPerPage,
  rosterId,
  searchQuery,
  sort,
  startDate,
  endDate
) => {
  let apiUrl = `${API_URL}/teamepicindex/${userId}/paginated?pageNumber=${pageNumber}&pageSize=${reportsPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`;
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`;
  }
  if (startDate) {
    apiUrl = apiUrl + `&startDate=${startDate}`;
  }
  if (endDate) {
    apiUrl = apiUrl + `&endDate=${endDate}`;
  }
  const { data: paginatedResultData } = await axios.get(apiUrl);

  const { data: reports, totalRecords: totalReports } = paginatedResultData;
  formatTeamReports(reports);
  return { reports, totalReports };
};

export const getExportedTeamReports = async (
  userId,
  pageNumber,
  reportsPerPage,
  rosterId,
  searchQuery,
  sort,
  startDate,
  endDate
) => {
  let apiUrl = `${API_URL}/teamepicindex/${userId}/export?pageNumber=${pageNumber}&pageSize=${reportsPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`;
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`;
  }
  if (startDate) {
    apiUrl = apiUrl + `&startDate=${startDate}`;
  }
  if (endDate) {
    apiUrl = apiUrl + `&endDate=${endDate}`;
  }
  const { data: reports } = await axios.get(apiUrl);
  formatTeamReports(reports);
  return reports;
};

export const getPaginatedReports = async (
  userId,
  pageNumber,
  reportsPerPage,
  rosterId,
  searchQuery,
  sort,
  startDate,
  endDate
) => {
  let apiUrl = `${API_URL}/epicindex/${userId}/paginated?pageNumber=${pageNumber}&pageSize=${reportsPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`;
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`;
  }
  if (startDate) {
    apiUrl = apiUrl + `&startDate=${startDate}`;
  }
  if (endDate) {
    apiUrl = apiUrl + `&endDate=${endDate}`;
  }
  const { data: paginatedResultData } = await axios.get(apiUrl);

  const { data: reports, totalRecords: totalReports } = paginatedResultData;
  formatIndividualReports(reports)
  return { reports, totalReports };
};

export const getExportedReports = async (
  userId,
  pageNumber,
  reportsPerPage,
  rosterId,
  searchQuery,
  sort,
  startDate,
  endDate
) => {
  let apiUrl = `${API_URL}/epicindex/${userId}/export?pageNumber=${pageNumber}&pageSize=${reportsPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`;
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`;
  }
  if (startDate) {
    apiUrl = apiUrl + `&startDate=${startDate}`;
  }
  if (endDate) {
    apiUrl = apiUrl + `&endDate=${endDate}`;
  }
  const { data: reports } = await axios.get(apiUrl);
  formatIndividualReports(reports);
  return reports;
};

export const deleteEpicIndexreportService = async (userId, reportId) => {
  const { data: response } = await axios.delete(
    `${API_URL}/epicindex/${userId}/${reportId}`
  );
  return response;
};

export const deleteTeamReportService = async (userId, teamReportId) => {
  const { data: response } = await axios.delete(
    `${API_URL}/teamreport/${userId}/${teamReportId}`
  );
  return response;
};

export const generateEpicIndexReportService = async (
  userId,
  athleteId,
  generalNotes,
  startDate,
  endDate,
  logo
) => {
  if (logo) {
    const formData = new FormData();
    formData.append("image", logo);
    await axios.post(`${API_URL}/coach/${userId}/reportlogo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  let apiUrl = `${API_URL}/epicindex/${userId}/${athleteId}`;
  if (startDate && endDate) {
    apiUrl += `?startDate=${startDate}&endDate=${endDate}`;
  }
  const { data } = await axios.post(apiUrl, { generalNotes });
  const { report } = data;
  return report;
};

export const generateTeamReportService = async (userId, rosterId) => {
  const { data: teamReport } = await axios.post(
    `${API_URL}/teamreport/${userId}/${rosterId}`,
    null
  );
  return teamReport;
};
