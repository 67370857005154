import React, { useEffect, useContext, useState } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import FetchMessage from "../../../../../components/fetchMessage/FetchMessage";
import SearchBar from "../../../../../components/searchbar/SearchBar";
import Table from "../../../../../components/table/Table";
import { AuthContext } from "../../../../../contexts/AuthContext";
import {
  getAthletes,
  getPaginatedAthletes,
} from "../../../../../services/athleteService";
import styles from "./SelectAthlete.module.scss";
import { ModalContext } from "../../../../../contexts/ModalContext";
import _ from "lodash";
import PaginatedTable from "../../../../paginatedtable/PaginatedTable";
import { determineAthleteSortOrder } from "../../../../../utilities/sortOrder";

const tableColumns = [
  { path: "firstName", label: "First Name" },
  { path: "middleName", label: "Middle Name" },
  { path: "lastName", label: "Last Name" },
];

const SelectAthlete = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [finalSearchQuery, setFinalSearchQuery] = useState("");
  const [totalAthletes, setTotalAthletes] = useState(0);
  const [athletesPerPage, setAthletesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const { state: authState } = useContext(AuthContext);
  const { replaceModal, hideModal } = useContext(ModalContext);
  const [athletes, setAthletes] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "lastName",
    order: "asc",
  });

  useEffect(() => {
    const fetchAthletes = async () => {
      try {
        const { userId } = authState;
        const sort = determineAthleteSortOrder(sortColumn);
        const {
          athletes: fetchedAthletes,
          totalAthletes: fetchedTotalAthletes,
        } = await getPaginatedAthletes(
          userId,
          currentPage,
          athletesPerPage,
          null,
          finalSearchQuery,
          sort
        );

        setAthletes(fetchedAthletes);
        setTotalAthletes(fetchedTotalAthletes);

        setIsFetching(false);
      } catch {}
    };
    fetchAthletes();
  }, [authState, currentPage, athletesPerPage, finalSearchQuery, sortColumn]);

  let modalContents = null;

  if (isFetching && !athletes) {
    modalContents = <FetchMessage message={"Fetching Athletes"} />;
  } else {
    let filteredAthletes = [];
    if (athletes) {
      filteredAthletes = athletes.filter(
        (athlete) =>
          athlete["firstName"]
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          athlete["middleName"]
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          athlete["lastName"].toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    const sortedAthletes = _.orderBy(
      filteredAthletes,
      [(user) => user[sortColumn.path].toLowerCase()],
      [sortColumn.order]
    );

    modalContents = (
      <div>
        <div className={styles.contentsContainer}>
          <div>Select your athlete</div>
          <SearchBar
            query={searchQuery}
            onChange={setSearchQuery}
            onSearch={(query) => setFinalSearchQuery(query)}
          />
          <PaginatedTable
            columns={tableColumns}
            data={athletes}
            sortColumn={sortColumn}
            onSort={(sortColumn) => setSortColumn(sortColumn)}
            total={totalAthletes}
            pageSize={athletesPerPage}
            onRowClick={(athlete) =>
              replaceModal("SelectEvent", { athleteId: athlete.id })
            }
            onShowSizeChange={(currentPage, athletesPerPage) =>
              setAthletesPerPage(athletesPerPage)
            }
            onPageChange={(page) => setCurrentPage(page)}
            onGotoChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Select Athlete</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>{modalContents}</ModalCardBody>
        <ModalCardFooter></ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default SelectAthlete;
