import Amplify, { Auth } from "aws-amplify";
import environment from "../environment/environment";

const { cognito } = environment;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: cognito.REGION,
    userPoolId: cognito.USER_POOL_ID,
    userPoolWebClientId: cognito.USER_POOL_WEB_ID,
  },
});

export const validateUserSession = async () => {
  try {
    const currentAuthUser = await Auth.currentAuthenticatedUser();
    const session = await Auth.userSession(currentAuthUser);
    if (session.isValid()) {
        const userInfo = await Auth.currentUserInfo();
        return userInfo.attributes.sub;;
    } else {
        return null;
    }
  } catch (err) {
    return null;
  }
};
