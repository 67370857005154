const development = {
    API_URL: "https://backend-service.dashrsystems-dev.com/api",
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_0Gpsv75iE',
        USER_POOL_WEB_ID: '7ql9l5puni7nn6h7fh8dg6a5ej'
    }

}

const production = {
    API_URL: "https://backend-service.dashrsystems.com/api",
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_pBduuy4yV',
        USER_POOL_WEB_ID: '6l2frk2ha0rssafc9fmjip36a1'
    }
}

const environment = process.env.REACT_APP_STAGE === 'production'
    ? production
    : development;

export default environment