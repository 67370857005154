import React from "react";
import Input from "../../components/form/input/Input";
import InputHelperText from "../../components/form/inputhelpertext/InputHelperText";
import InputLabel from "../../components/form/inputlabel/InputLabel";
import styles from "./TextField.module.scss";

const TextField = ({
  id,
  label,
  value,
  inputType,
  onChange,
  helperText,
  required,
  readOnly,
}) => {
  return (
    <div className={styles.textField}>
      <InputLabel label={label} required={required} />
      <Input id={id} onChange={onChange} value={value} type={inputType} readOnly={readOnly} />
      {helperText && <InputHelperText helperText={helperText} />}
    </div>
  );
};

export default TextField;
