import React from "react";
import { decimalNumberPattern } from "../../../../../common/patterns/patterns";
import Event from "./Event";

const fields = [
  { type: "input", label: "Jump Distance", property: "jumpDistance" },
];

const validations = {
  jumpDistance: {
    pattern: {
      value: decimalNumberPattern,
      message: "Jump distance needs to be a number",
    },
  },
};

const initialValues = {
  jumpDistance: "0.0"
}

const isValidApproachVertical = (values) => {
  const jumpDistance = parseFloat(values.jumpDistance);

  if (jumpDistance === 0) {
    return {
      isValid: false,
      message: "Jump distance cannot be 0",
      eventFields: null,
    };
  }

  const eventFields = {
    type: "ApproachVertical",
    jumpDistance,
  };
  return { isValid: true, message: null, eventFields };
};

const ApproachVertical = ({ athleteId, isMetric }) => {
  return (
    <Event
      title={"Add Approach Vertical"}
      athleteId={athleteId}
      isMetric={isMetric}
      fields={fields}
      initialValues={initialValues}
      validations={validations}
      isValidEvent={isValidApproachVertical}
    />
  );
};

export default ApproachVertical;
