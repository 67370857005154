import React from "react";
import _ from "lodash";

import styles from "./TableBody.module.scss";

const TableBody = ({ columns, data, onRowClick }) => {
  const renderTableCell = (item, column) => {
    if (column.content) {
      return column.content(item);
    }
    let tableItem = _.get(item, column.path);
    if (!tableItem) {
      tableItem = "-";
    }
    return tableItem;
  };

  const createKey = (item, column) => {
    return item._id + (column.path || column.key);
  };

  return (
    <tbody className={styles.tableBody}>
      {data.map((item) => {
        return (
          <tr key={item.id} onClick={() => onRowClick(item)}>
            {columns.map((column) => {
              return (
                <td key={createKey(item, column)}>
                  {renderTableCell(item, column)}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
