import React, { createContext, useReducer } from "react";
import { determineAthleteSortOrder } from "../utilities/sortOrder";

const initialState = {
  currentPage: 1,
  athletesPerPage: 20,
  totalAthletes: null,
  activeRoster: -1,
  athletes: null,
  rosters: null,
  searchQuery: null,
  sortColumn: { path: "lastName", order: "asc" },
  sort: 'LastName'
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIAL_STATE": {
      return initialState;
    }
    case "FETCH_ROSTERS": {
      const rosters = action.payload;
      return { ...state, rosters };
    }
    case "FETCH_ATHLETES": {
      const { athletes, totalAthletes } = action.payload;
      return {
        ...state,
        athletes,
        totalAthletes,
      };
    }
    case "SET_SORT_COLUMN": {
      const sortColumn = action.payload;
      const sort = determineAthleteSortOrder(sortColumn)
      return { ...state, sortColumn, sort };
    }
    case "UPDATE_ATHLETE": {
      const athlete = action.payload;
      const athletes = state.athletes.map((a) => {
        if (a.id === athlete.id) {
          return athlete;
        }
        return a;
      });
      return { ...state, athletes };
    }
    case "ADD_ROSTER": {
      const roster = action.payload;
      const rosters = [...state.rosters, roster];
      return { ...state, rosters };
    }
    case "CHANGE_ROSTER": {
      const activeRoster = action.payload;
      return { ...state, activeRoster };
    }
    case "DELETE_ROSTER":
      const rosterId = action.payload;
      const rosters = state.rosters.filter((roster) => roster.id !== rosterId);
      return { ...state, activeRoster: -1, rosters };
    case "CHANGE_CURRENT_PAGE":
      const pageNumber = action.payload;
      return { ...state, currentPage: pageNumber };
    case "CHANGE_ATHLETES_PER_PAGE":
      const athletesPerPage = action.payload;
      return { ...state, athletesPerPage };
    case "SET_SEARCH_QUERY":
      const searchQuery = action.payload;
      return { ...state, searchQuery };
    default:
      return state;
  }
};

const AthletesContext = createContext(initialState);

const AthletesProvider = ({ children }) => {
  const [athletesState, athletesDispatch] = useReducer(reducer, initialState);

  return (
    <AthletesContext.Provider
      value={{
        athletesState,
        athletesDispatch,
        
      }}
    >
      {children}
    </AthletesContext.Provider>
  );
};

export { AthletesContext, AthletesProvider };
