import React, { useContext, useState } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./AddExistingAthlete.module.scss";
import Button from "../../../../components/button/Button";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { addRosterAthleteService } from "../../../../services/rosterService";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { getPaginatedAthletes } from "../../../../services/athleteService";

const AddExistingAthlete = ({ athlete, roster }) => {
  const [progressMessage, setProgressMessage] = useState(null);
  const { replaceModal, hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch, athletesState } = useContext(AthletesContext);
  const [isAddButtonDisabled, setAddButtonDisabled] = useState(false);

  const handleAddExistingAthlete = async () => {
    try {
      setProgressMessage({
        isLoading: true,
        message: "Adding Athlete to Roster",
        isError: false,
      });
      const { userId } = authState;
      await addRosterAthleteService(userId, roster.id, athlete.id);
      const { activeRoster, currentPage, athletesPerPage, searchQuery, sort } =
        athletesState;
      const { athletes, totalAthletes } = await getPaginatedAthletes(
        userId,
        currentPage,
        athletesPerPage,
        activeRoster,
        searchQuery,
        sort
      );
      athletesDispatch({
        type: "FETCH_ATHLETES",
        payload: { athletes, totalAthletes },
      });
      setProgressMessage({
        isLoading: false,
        message: "Sucessfully Added Athlete",
        isError: false,
      });
      setAddButtonDisabled(true);
    } catch (err) {
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed deleting roster, please try again",
            isError: true,
          });
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle
            className={styles.modalTitle}
          >{`Add Existing Athlete To ${roster.name}`}</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.centeredWrapper}>
            <div>
              Are you sure you want to add{" "}
              <span
                className={styles.athleteRosterData}
              >{`${athlete.firstName} ${athlete.middleName} ${athlete.lastName}`}</span>{" "}
              to <span className={styles.athleteRosterData}>{roster.name}</span>
              ?
            </div>
          </div>
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button
              type={"back"}
              onClick={() => replaceModal("AddRosterAthlete", { roster })}
            >
              Back
            </Button>
            <Button
              onClick={handleAddExistingAthlete}
              disabled={isAddButtonDisabled}
            >
              Add Existing Athlete
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default AddExistingAthlete;
