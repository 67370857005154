import { useEffect, useContext } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import Dashboard from "./layouts/dashboard/Dashboard";
import { validateUserSession } from "./services/authService";
import { AuthContext } from "./contexts/AuthContext";
import Authentication from "./layouts/authentication/Authentication";

import "bulma/css/bulma.css";
import 'antd/dist/antd.css';
import { getUserService } from "./services/coachService";
import { AppContext } from "./contexts/AppContext";
import { AccountContext } from "./contexts/AccountContext";
import { getCompanyService } from "./services/thirdPartyIntegrationService";

const RedirectRoute = ({ isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        typeof isAuthenticated === "undefined" || isAuthenticated === false ? (
          <Redirect to="/login" />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

const App = () => {
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  const { setAccountInfo } = useContext(AccountContext)
  const { appDispatch} = useContext(AppContext);
  const { isAuthenticated } = authState;

  useEffect(() => {
    const fetchUserId = async () => {
      const userId = await validateUserSession();
      if (userId) {
        const accountSettings = await getUserService(userId);
        const companies = await getCompanyService();

        const { metricSettingEnabled, thirdPartySettings } = accountSettings;

        const thirdPartyIntegration = [];
        companies.forEach(company => {
          const { id, companyName } = company
          let isIntegrated = false
          const approvedCompany = thirdPartySettings.find(tps => tps.companyId === id)
          if (approvedCompany) {
            isIntegrated = true
          }
          thirdPartyIntegration.push( { id, name: companyName, isIntegrated })
        })

        accountSettings.thirdPartyIntegration = thirdPartyIntegration
        delete accountSettings.thirdPartySettings
        setAccountInfo(accountSettings);
        appDispatch({ type: 'SET_UNITS_SETTING', payload: metricSettingEnabled });      
        authDispatch({ type: "AUTH_USER", payload: userId });
        
      } else {
        authDispatch({ type: "UNAUTH_USER" });
      }
    };
    fetchUserId();
  }, [authDispatch, appDispatch, setAccountInfo]);
  console.log(isAuthenticated)
  if (isAuthenticated === null) {
    return null;
  }

  return (
    <Switch>
      <Route path="/login" component={Authentication} />
      <Route path="/signup" component={Authentication} />
      <Route path="/confirm" component={Authentication} />
      <Route path="/changepassword" component={Authentication} />
      <Route path="/forgotpassword" component={Authentication} />
      <Route path="/dashboard" component={Dashboard} />
      <RedirectRoute exact path="/" isAuthenticated={isAuthenticated} />
    </Switch>
  );
};

export default App;
