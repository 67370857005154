import { Card, Columns, CardContent } from "bloomer";
import React, { useEffect, useContext } from "react";
import ProfileOverview from "../../containers/profileoverview/ProfileOverview";
import { AppContext } from "../../contexts/AppContext";
import styles from "./Account.module.scss";
import DeleteData from "./delete/DeleteData";
import MetricSettings from "./metricsettings/MetricSettings";
import ThirdPartyIntegration from "./thirdPartyIntegration/ThirdPartyIntegration";

const Account = () => {
  const { appDispatch } = useContext(AppContext)
  useEffect(() => {
    appDispatch({ type: "SET_CURRENT_PAGE_TITLE", payload: "Account" });

  }, [appDispatch])
  return (
    <Columns>
      <div className="column is-flex">
        <Card className={styles.card}>
          <CardContent>
            <ProfileOverview />
          </CardContent>
        </Card>
      </div>
      <div className="column is-flex">
        <Card className={styles.card}>
          <CardContent>
            <DeleteData/>
            <MetricSettings />
            <ThirdPartyIntegration />
          </CardContent>
        </Card>
      </div>
    </Columns>
  );
};

export default Account;
