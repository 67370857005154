import React from "react";
import styles from "./MenuItem.module.scss";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const MenuItem = ({ label, icon, path }) => {
  return (
    <div className={styles.menuItem}>
      <NavLink
        className={styles.menuLink}
        activeClassName={styles.menuLinkActive}
        to={path}
      >
        <li className={styles.menuItem}>
          <i className={classNames(styles.menuIcon, "material-icons")}>
            {icon}
          </i>
          <p className={styles.menuLabel}>{label}</p>
        </li>
      </NavLink>
    </div>
  );
};

export default MenuItem;
