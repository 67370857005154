import React, { useState } from "react";
import classNames from "classnames";
import styles from "./SearchBar.module.scss";

const SearchBar = ({ query, onChange, onSearch }) => {
  return (
    <div className={styles.searchBarContainer}>
        <div className="field has-addons">
          <div className="control">
            <input
              className={classNames("input", styles.searchBar)}
              type="text"
              placeholder="Search..."
              value={query}
              onChange={(e) => {
                e.preventDefault();
                onChange(e.target.value);
              }}
              onKeyPress={(e) => {
                if(e.key === 'Enter') {
                  e.preventDefault();
                  onSearch(query);
                }
              }}
            />
          </div>
          <div
            className={`control ${styles.searchIconContainer}`}
            onClick={() => onSearch ? onSearch(query) : null}
          >
            <i className={classNames(styles.searchIcon, "material-icons")}>
              search
            </i>
          </div>
        </div>
    </div>
  );
};

export default SearchBar;
