import { Auth } from "aws-amplify";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/Button";
import ProgressMessage from "../../components/progressmessage/ProgressMessage";
import TextField from "../../containers/textfield/TextField";
import { AuthContext } from "../../contexts/AuthContext";
import validateConfirm from "./confirmValidation";

const Confirm = () => {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [progressMessage, setProgressMessage] = useState(null);
  const [confirmValidationError, setConfirmValidationError] = useState(null)
  const { state: authState } = useContext(AuthContext);
  const history = useHistory();
  const { username } = authState;

  useEffect(() => {
    if (!username) {
      history.push("/");
    }
  }, [username, history]);

  const handleConfirmCode = async () => {
    try {
      const validationError = validateConfirm(confirmationCode);
      if (validationError) {
        setConfirmValidationError(validationError)
        return;
      }

      setProgressMessage({
        isLoading: true,
        message: "Confirming...",
        isError: false,
      });
      const { username } = authState;
      await Auth.confirmSignUp(username, confirmationCode);
      history.push("/");
    } catch (e) {
      const { message } = e;
      setProgressMessage({ isLoading: false, message, isError: true });
    }
  };

  const handleResendCode = async () => {
    try {
      setProgressMessage({
        isLoading: true,
        message: "Resending...",
        isError: false,
      });
      const { username } = authState;
      await Auth.resendSignUp(username);
      setProgressMessage({
        isLoading: false,
        message: "Confirmation code sent",
        isError: false,
      });
    } catch (e) {
      console.log(e);
      const { message } = e;
      setProgressMessage({ isLoading: false, message, isError: true });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <>
      {progressMessageComponent}
      <TextField
        label={"Confirmation code"}
        value={confirmationCode}
        onChange={(e) => {
          e.preventDefault();
          setConfirmationCode(e.target.value);
        }}
        helperText={confirmValidationError}
      />
      <Button onClick={handleConfirmCode} fullWidth>CONFIRM</Button>
      <Button onClick={handleResendCode} fullWidth>RESEND CODE</Button>
    </>
  );
};

export default Confirm;
