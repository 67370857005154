const getSharedDetails = (result) => {
  console.log(result)
  const sharedDetails = [];
  sharedDetails.push({ label: "First Name", value: result.firstName });
  sharedDetails.push({ label: "Middle Name", value: result.middleName });
  sharedDetails.push({ label: "Last Name", value: result.lastName });
  sharedDetails.push({ label: "Event", value: result.event });
  sharedDetails.push({ label: "Lane ID", value: result.laneID });

  return sharedDetails;
};

const getMultiSplitDetails = (multiSplit) => {
  const multiSplitDetails = [...getSharedDetails(multiSplit)];
  const {
    splitTime: splitTimes,
    finalTime,
    startDistance,
    isMetric,
  } = multiSplit;
  const { distance } = finalTime;

  if (startDistance) {
    const startDistanceLabel = `Start Distance (${isMetric ? "M" : "YDS"})`;
    multiSplitDetails.push({
      label: startDistanceLabel,
      value: multiSplit.startDistance,
    });
  }

  if (splitTimes) {
    splitTimes.forEach((splitTime, index) => {
      const { time, distance } = splitTime;
      let splitTimeLabel = `Split Time ${index + 1}`;
      if (distance) {
        splitTimeLabel =
          splitTimeLabel + ` (${distance} ${isMetric ? "M" : "YDS"})`;
      }
      multiSplitDetails.push({ label: splitTimeLabel, value: time });
    });
  }
  let finalTimeLabel = `Final Time`;
  if (finalTime.distance) {
    finalTimeLabel =
      finalTimeLabel + ` (${distance} ${isMetric ? "M" : "YDS"})`;
  }
  multiSplitDetails.push({ label: finalTimeLabel, value: finalTime.time });
  return multiSplitDetails;
};

const getProAgilityDetails = (proAgility) => {
  const proAgilityDetails = [...getSharedDetails(proAgility)];

  proAgilityDetails.push({
    label: "Split Time (S)",
    value: proAgility.splitTime,
  });
  proAgilityDetails.push({
    label: "Final Time (S)",
    value: proAgility.finalTime,
  });

  return proAgilityDetails;
};

const getLiftingDetails = (lifting) => {
  const liftingDetails = [...getSharedDetails(lifting)];

  liftingDetails.push({ label: "Lift", value: lifting.lift });
  liftingDetails.push({ label: "Weight", value: lifting.weight });
  liftingDetails.push({ label: "Reps", value: lifting.reps });

  return liftingDetails;
};

const getLDrillDetails = (lDrill) => {
  const lDrillDetails = [...getSharedDetails(lDrill)];

  lDrillDetails.push({ label: "Final Time (S)", value: lDrill.finalTime });

  return lDrillDetails;
};

const getLapDetails = (lap) => {
  const lapDetails = [...getSharedDetails(lap)];

  lapDetails.push({ label: "Time 1 (S)", value: lap.time1 });
  lapDetails.push({ label: "Time 2 (S)", value: lap.time2 });
  lapDetails.push({ label: "Time 3 (S)", value: lap.time3 });
  lapDetails.push({ label: "Time 4 (S)", value: lap.time4 });

  return lapDetails;
};

const getReactDrillDetails = (reactDrill) => {
  const reactDrillDetails = [...getSharedDetails(reactDrill)];
  reactDrillDetails.push({
    label: "Split Time (S)",
    value: reactDrill.splitTime,
  });
  reactDrillDetails.push({
    label: "Final Time (S)",
    value: reactDrill.finalTime,
  });
  if (reactDrill.reactionTime) {
    reactDrillDetails.push({
      label: "Reaction Time (S)",
      value: reactDrill.reactionTime,
    });
  }
  reactDrillDetails.push({
    label: "Direction",
    value: reactDrill.isLeft ? "Left" : "Right",
  });
  return reactDrillDetails;
};

const getVerticalDetails = (vertical) => {
  const verticalDetails = [...getSharedDetails(vertical)];

  verticalDetails.push({
    label: `Jump Distance (${vertical.isMetric ? "CM" : "IN"})`,
    value: vertical.jumpDistance,
  });

  return verticalDetails;
};

const get505AgilityDetails = (_505Agility) => {
  const _505AgilityDetails = [...getSharedDetails(_505Agility)];

  _505AgilityDetails.push({
    label: "Final Time (S)",
    value: _505Agility.finalTime,
  });

  return _505AgilityDetails;
};

const getTTestDetails = (tTest) => {
  const tTestDetails = [...getSharedDetails(tTest)];

  tTestDetails.push({
    label: "Final Time (S)",
    value: tTest.finalTime,
  });

  return tTestDetails;
};

const getRastDetails = (rast) => {
  const rastDetails = [...getSharedDetails(rast)];

  rastDetails.push({ label: "Split Time 1 (S)", value: rast.splitTime1 });
  rastDetails.push({ label: "Split Time 2 (S)", value: rast.splitTime2 });
  rastDetails.push({ label: "Split Time 3 (S)", value: rast.splitTime3 });
  rastDetails.push({ label: "Split Time 4 (S)", value: rast.splitTime4 });
  rastDetails.push({ label: "Split Time 5 (S)", value: rast.splitTime5 });
  rastDetails.push({ label: "Final Time (S)", value: rast.finalTime });

  return rastDetails;
};

const getBroadJumpDetails = (broadJump) => {
  const broadJumpDetail = [...getSharedDetails(broadJump)];

  broadJumpDetail.push({
    label: `Jump Distance (${broadJump.isMetric ? "CM" : "IN"})`,
    value: broadJump.jumpDistance,
  });

  return broadJumpDetail;
};

const getApproachVerticalDetails = (approachVertical) => {
  const approachVerticalDetails = [...getSharedDetails(approachVertical)];

  approachVerticalDetails.push({
    label: `Jump Distance (${approachVertical.isMetric ? "CM" : "IN"})`,
    value: approachVertical.jumpDistance,
  });

  return approachVerticalDetails;
};

const getDashrBetaDetails = (dashrBeta) => {
  const dashrBetaDetails = [...getSharedDetails(dashrBeta)];

  dashrBetaDetails.push({
    label: "Subtype",
    value: dashrBeta.subtype,
  });
  dashrBetaDetails.push({
    label: "Data",
    value: dashrBeta.data,
  });

  return dashrBetaDetails;
};

const resultDetailLookup = {
  Dash: getMultiSplitDetails,
  ProAgility: getProAgilityDetails,
  Lifting: getLiftingDetails,
  LDrill: getLDrillDetails,
  ReactShuttle: getReactDrillDetails,
  Vertical: getVerticalDetails,
  Lap: getLapDetails,
  "505AgilityTest": get505AgilityDetails,
  TTest: getTTestDetails,
  RAST: getRastDetails,
  Flying: getMultiSplitDetails,
  ApproachVertical: getApproachVerticalDetails,
  DashrBeta: getDashrBetaDetails,
  BroadJump: getBroadJumpDetails,
  ReactAgility: getReactDrillDetails,
};

const getResultDetails = (result) => {
  const resultDetailFunction = resultDetailLookup[result.event];
  return resultDetailFunction(result);
};

export default getResultDetails;
