import { Auth } from "aws-amplify";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/Button";
import ProgressMessage from "../../components/progressmessage/ProgressMessage";
import TextField from "../../containers/textfield/TextField";
import { AuthContext } from "../../contexts/AuthContext";
import { createUser } from "../../services/coachService";
import validateSignup from "./signupValidation";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [progressMessage, setProgressMessage] = useState(null);
  const [validationMessages, setValidationMessages] = useState({
    email: null,
    password: null,
    confirmPassword: null,
  });
  const { dispatch: authDispatch } = useContext(AuthContext);
  const history = useHistory();

  const handleSignup = async () => {
    try {
      const validationErrors = validateSignup(email, password, confirmPassword);
      if (validationErrors) {
        const { email, password, confirmPassword } = validationErrors;
        setValidationMessages({ email, password, confirmPassword });
        return;
      }

      setProgressMessage({
        isLoading: true,
        message: "Signing up...",
        isError: false,
      });
      const user = await Auth.signUp(email, password);
      await createUser(user.userSub, email)
      authDispatch({ type: "CONFIRM_USER", payload: email });
      history.push("/confirm");
    } catch (e) {
      console.log(e);
      const { message } = e;
      setProgressMessage({ isLoading: false, message, isError: true });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <>
      {progressMessageComponent}
      <TextField
        label={"Email"}
        value={email}
        onChange={(e) => {
          e.preventDefault();
          setEmail(e.target.value);
        }}
        helperText={validationMessages.email}
      />
      <TextField
        label={"Password"}
        value={password}
        inputType={'password'}
        onChange={(e) => {
          e.preventDefault();
          setPassword(e.target.value);
        }}
        helperText={validationMessages.password}
      />
      <TextField
        label={"Confirm Password"}
        value={confirmPassword}
        inputType={'password'}
        onChange={(e) => {
          e.preventDefault();
          setConfirmPassword(e.target.value);
        }}
        helperText={validationMessages.confirmPassword}
      />
      <Button onClick={handleSignup} fullWidth>SIGN UP</Button>
    </>
  );
};

export default Signup;
