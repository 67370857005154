import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import Select from "../../../../../components/select/Select";
import { ModalContext } from "../../../../../contexts/ModalContext";
import Button from "../../../../../components/button/Button";
import styles from "./SelectEvent.module.scss";

const events = [
  { id: 1, component: "ProAgility", name: "Pro Agility" },
  { id: 2, component: "Vertical", name: "Vertical" },
  { id: 3, component: "LDrill", name: "LDrill" },
  { id: 4, component: "ApproachVertical", name: "Approach Vertical" },
  { id: 5, component: "BroadJump", name: "Broad Jump" },
  { id: 6, component: "ReactAgility", name: "React Agility" },
  { id: 7, component: "ReactShuttle", name: "React Shuttle" },
  { id: 8, component: "TTest", name: "T Test" },
  { id: 9, component: "_505AgilityTest", name: "505 Agility Test" },
  { id: 10, component: "Lifting", name: "Lifting" },
  { id: 11, component: "Lap", name: "Lap" },
  { id: 12, component: "SelectSplits", name: "Dash" },
  { id: 13, component: "SelectSplits", name: "Flying" },
];

const units = [
  { id: 1, name: "Imperial", value: false },
  { id: 2, name: "Metric", value: true },
];

const SelectEvent = ({ athleteId }) => {
  const [activeEvent, setActiveEvent] = useState(events[0]);
  const [activeUnit, setActiveUnit] = useState(units[0]);
  const { replaceModal, hideModal } = useContext(ModalContext);

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Select Event</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.selectEventContainer}>
            <div>Select Event Type</div>
            <div>
              <Select
                options={events}
                value={activeEvent.id}
                onOptionsChange={(eventId) => {
                  const selectedEvent = events.filter((e) => {
                    return e.id === Number(eventId);
                  })[0];
                  setActiveEvent(selectedEvent);
                }}
              />
            </div>
            <div>Select Units</div>
            <div>
              <Select
                options={units}
                value={activeUnit.id}
                onOptionsChange={(unitId) => {
                  const selectedUnit = units.filter((unit) => {
                    return unit.id === Number(unitId);
                  })[0];
                  setActiveUnit(selectedUnit);
                }}
              />
            </div>
          </div>
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => replaceModal("SelectAthlete")}>
              Back
            </Button>
            <Button
              onClick={() =>
                replaceModal(activeEvent.component, {
                  athleteId,
                  isMetric: activeUnit.value,
                  event: activeEvent.name
                })
              }
            >
              Next
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default SelectEvent;
