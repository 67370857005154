import React from "react";
import { Pagination } from "antd";
import Table from "../../components/table/Table";

import styles from "./PaginatedTable.module.scss";

const PaginatedTable = ({
  columns,
  data,
  sortColumn,
  onSort,
  onRowClick,
  onShowSizeChange,
  onPageChange,
  onGotoChange,
  pageSize,
  total
}) => {
  return (
    <>
      <Table
        columns={columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
        onRowClick={onRowClick}
      />
      <div className={styles.pagination}>
        <Pagination
          showQuickJumper
          defaultCurrent={1}
          total={total}
          onChange={onGotoChange}
          pageSize={pageSize}
          showSizeChanger={true}
          pageSizeOptions={[20, 50, 100]}
          onPageChange={onPageChange}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </>
  );
};

export default PaginatedTable;
