import React from "react";
import { decimalNumberPattern } from "../../../../../common/patterns/patterns";
import Event from "./Event";

const fields = [
  { type: "input", label: "Split Time", property: "splitTime" },
  { type: "input", label: "Final Time", property: "finalTime" },
];

const validations = {
  splitTime: {
    pattern: {
      value: decimalNumberPattern,
      message: "Split time needs to be a number",
    },
  },
  finalTime: {
    pattern: {
      value: decimalNumberPattern,
      message: "Final time needs to be a number",
    },
  },
};

const initialValues = { splitTime: "0.0", finalTime: "0.0" };

const isValidProAgility = (values) => {
  const splitTime = parseFloat(values.splitTime);
  const finalTime = parseFloat(values.finalTime);

  if (finalTime === 0) {
    return {
      isValid: false,
      message: "Final time cannot be 0",
      eventFields: null,
    };
  }

  if (splitTime >= finalTime) {
    return {
      isValid: false,
      message: "Final time cannot equal or be smaller than split time",
      eventFields: null,
    };
  }

  const eventFields = {
    type: "ProAgility",
    splitTime,
    finalTime,
  };
  return { isValid: true, message: null, eventFields };
};

const ProAgility = ({ athleteId, isMetric }) => {
  return (
    <Event
      title={"Add Pro Agility"}
      athleteId={athleteId}
      isMetric={isMetric}
      fields={fields}
      initialValues={initialValues}
      validations={validations}
      isValidEvent={isValidProAgility}
    />
  );
};

export default ProAgility;
