import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import Select from "../../../../../components/select/Select";
import { ModalContext } from "../../../../../contexts/ModalContext";
import Button from "../../../../../components/button/Button";
import styles from "./SelectSplits.module.scss";

const numSplits = [
  { id: 0, name: "0", value: 0 },
  { id: 1, name: "1", value: 1 },
  { id: 2, name: "2", value: 2 },
  { id: 3, name: "3", value: 3 },
  { id: 4, name: "4", value: 4 },
];

const distanceOptions = [
  { id: 1, name: "Yes", value: 1 },
  { id: 2, name: "No", value: 2 },
];

const SelectSplits = ({ athleteId, event, isMetric }) => {
  const { replaceModal, hideModal } = useContext(ModalContext);
  const [activeNumSplits, setActiveNumSplits] = useState(0);
  const [activeDistance, setActiveDistance] = useState(1);
  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Select Number of Splits</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.selectEventContainer}>
            <div>Select Number of Splits</div>
            <div>
              <Select
                options={numSplits}
                value={activeNumSplits}
                onOptionsChange={(splitNumber) => {
                  setActiveNumSplits(parseInt(splitNumber));
                }}
              />
            </div>
            <div>Are there distance associated with your splits?</div>
            <div>
              <Select
                options={distanceOptions}
                value={activeDistance}
                onOptionsChange={(distance) => {
                  setActiveDistance(parseInt(distance));
                }}
              />
            </div>
          </div>
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => replaceModal("SelectAthlete")}>
              Back
            </Button>
            <Button
              onClick={() =>
                replaceModal(event, {
                  athleteId,
                  numSplits: activeNumSplits,
                  isMetric,
                  isDistanceAssociated: activeDistance === 1,
                })
              }
            >
              Next
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default SelectSplits;
