import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const exportToExcelSpreadsheet = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Athletes");
  const workbookOut = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  const buffer = new ArrayBuffer(workbookOut.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i !== workbookOut.length; ++i) {
    view[i] = workbookOut.charCodeAt(i) & 0xff;
  }
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    `${fileName}.xlsx`
  );
};
