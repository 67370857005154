import React from "react";

import styles from "./TableHead.module.scss";

const TableHead = ({ columns, sortColumn, onSort }) => {
  const raiseSort = (path) => {
    const newSortColumn = { path };
    if (sortColumn.path === path) {
      newSortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    } else {
      newSortColumn.order = "asc";
    }
    onSort(newSortColumn);
  };

  return (
    <thead className={styles.tableHead}>
      <tr>
        {columns.map((column) => {
          return (
            <th
              key={column.label}
              onClick={
                onSort
                  ? () => {
                      raiseSort(column.path);
                    }
                  : null
              }
            >
              {column.label}
              {sortColumn && (
                <SortIcon column={column} sortColumn={sortColumn} />
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const SortIcon = ({ column, sortColumn }) => {
  // console.log('sort icon');
  // console.log(column);
  // console.log(sortColumn)
  if (column.path !== sortColumn.path) {
    return <i className={`fa fa-sort ${styles.sortIcon}`} />;
  }
  if (sortColumn.order === "asc") {
    return <i className={`fa fa-sort-up ${styles.sortIcon}`} />;
  }
  return <i className={`fa fa-sort-down ${styles.sortIcon}`} />;
};

export default TableHead;
