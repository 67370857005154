import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import Button from "../../../../components/button/Button";
import { ModalContext } from "../../../../contexts/ModalContext";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { AuthContext } from "../../../../contexts/AuthContext";
import {
  getPaginatedAthletes,
  uploadAthletesService,
} from "../../../../services/athleteService";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import { FilePond } from "react-filepond";
import styles from "./UploadResult.module.scss";
import "filepond/dist/filepond.min.css";
import { getPaginatedEvents, uploadResultsService } from "../../../../services/resultsService";
import { ResultsContext } from "../../../../contexts/ResultsContext";

const UploadResult = () => {
  
  const { hideModal } = useContext(ModalContext);
  const [progressMessage, setProgressMessage] = useState(null);
  const { state: authState } = useContext(AuthContext);
  const { athletesState, athletesDispatch } = useContext(AthletesContext);
  const [uploadedResults, setUploadedResults] = useState([]);
  const { activeRoster } = athletesState;
  const { resultsState, resultsDispatch } = useContext(ResultsContext);

  const handleUploadResults = async () => {
    setProgressMessage({
      isLoading: true,
      message: "Uploading Athletes...",
      isError: false,
    });
    if (uploadedResults.length === 0) {
      setProgressMessage({
        isLoading: false,
        message: "You must upload a file",
        isError: true,
      });
    }
    try {
      const { userId } = authState;

      let resultSpreadsheet = new FormData();
      resultSpreadsheet.set("File", uploadedResults[0]);

      const response = await uploadResultsService(userId, resultSpreadsheet);

      const {
        currentPage,
        resultsPerPage,
        searchQuery,
        startDateFilter,
        endDateFilter,
        activeRosterFilter,
        sort,
      } = resultsState;

      const fetchedPaginatedResults = await getPaginatedEvents(
        authState.userId,
        currentPage,
        resultsPerPage,
        activeRosterFilter,
        searchQuery,
        sort,
        startDateFilter,
        endDateFilter
      );
      const { results, totalResults } = fetchedPaginatedResults;
      resultsDispatch({
        type: "SET_RESULTS",
        payload: {
          results,
          totalResults,
        },
      });
      setProgressMessage({
        isLoading: false,
        message: "Successfully uploaded results.",
        isError: false,
      });
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed adding roster, please try again",
            isError: true,
          });
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Upload Results</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.uploadMessage}>
            Note: Please look/use the template on how to properly upload results
          </div>
          <FilePond
            files={uploadedResults}
            onupdatefiles={(fileItems) =>
              setUploadedResults(fileItems.map((fileItem) => fileItem.file))
            }
          />
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button
              href={
                "https://dashr-roster-excel-files.s3.amazonaws.com/resultstemplate.xlsx"
              }
              type="back"
            >
              Download Template
            </Button>
            <Button onClick={handleUploadResults}>Upload Results</Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default UploadResult;
