import { useState } from "react";

export function useForm({ validations, initialValues = {} }) {
  if (!validations) {
    throw new Error("the option `validations` is required");
  }

  if (typeof validations !== "object") {
    throw new Error("the option `validations` should be an object");
  }

  if (typeof initialValues !== "object") {
    throw new Error("the option `initialValues` should be an object");
  }
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  function validateField(name, value) {
    const rules = validations[name];

    if (rules) {
      if (rules.required) {

        if (!value || (value && !value.trim())) {
          return typeof rules.required === "string"
            ? rules.required
            : "required";
        }
      }

      if (rules.pattern) {
        if (!new RegExp(rules.pattern.value).exec(value)) {
          return rules.pattern.message || "invalid";
        }
      }

      if (rules.validate && typeof rules.validate === "function") {
        const error = rules.validate(value);
        if (error) {
          return error;
        }
      }
    }
    return "";
  }

  function bindField(name) {
    if (!name) {
      throw new Error("The field name parameter is required");
    }

    if (name && typeof name !== "string") {
      throw new Error("The field name should be a string");
    }

    return {
      value: values[name] || "",
      onChange: (e) => {
        let result = e;
        if (e.target) {
          result = e.target.value
        }
        setValues((state) => ({
          ...state,
          [name]: result,
        }));

        setErrors((state) => ({
          ...state,
          [name]: validateField(name, result),
        }));
      },
    };
  }

  function isValid() {
    const errorMessages = {};

    for (const property in validations) {
      if (Boolean(validateField(property, values[property]))) {
        errorMessages[property] = validateField(property, values[property]);
      }
    }
    // console.log(errorMessages);
    if (Object.keys(errorMessages).length !== 0) {
      setErrors((state) => ({
        ...state,
        ...errorMessages,
      }));
      return false;
    }
    return true;

    // const hasErrors = Object.keys(validations).some((name) =>
    //   Boolean(validateField(name, values[name]))
    // );

    // return !hasErrors;
  }

  return {
    values,
    errors,
    validateField,
    bindField,
    isValid,
  };
}
