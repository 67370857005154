import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import Button from "../../../../components/button/Button";
import { ModalContext } from "../../../../contexts/ModalContext";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { AuthContext } from "../../../../contexts/AuthContext";
import { deleteAthleteService, getPaginatedAthletes } from "../../../../services/athleteService";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import styles from "./DeleteAthlete.module.scss";
import { deleteRosterAthleteService } from "../../../../services/rosterService";

const DeleteAthlete = ({ id, firstName, lastName, roster }) => {
  const { hideModal } = useContext(ModalContext);
  const [progressMessage, setProgressMessage] = useState(null);
  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch, athletesState } = useContext(AthletesContext);
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [backButtonLabel, setBackButtonLabel] = useState("Cancel");

  const handleDeleteAthlete = async () => {
    setProgressMessage({
      isLoading: true,
      message: "Deleting Athlete...",
      isError: false,
    });
    try {
      const { userId } = authState;
      if (roster) {
        await deleteRosterAthleteService(userId, roster, id);
      } else {
        await deleteAthleteService(userId, id);
      }
      const { activeRoster, currentPage, athletesPerPage, searchQuery, sort } =
        athletesState;
      const { athletes, totalAthletes } = await getPaginatedAthletes(
        userId,
        currentPage,
        athletesPerPage,
        activeRoster,
        searchQuery,
        sort
      );
      athletesDispatch({
        type: "FETCH_ATHLETES",
        payload: { athletes, totalAthletes },
      });
      setProgressMessage({
        isLoading: false,
        message: "Sucessfully Deleted Athlete",
        isError: false,
      });
      setDeleteButtonDisabled(true);
      setBackButtonLabel("Exit");
    } catch (err) {
      setProgressMessage({
        isLoading: false,
        message: "Failed Deleting Athlete, Please Try Again",
        isError: true,
      });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }
  const modalTitle = roster ? "Delete Athlete from Roster" : "Delete Athlete";
  let modalMessage = `Are you sure you want to permanently delete ${firstName} ${lastName}?`;
  if (roster) {
    modalMessage = `Are you sure you want to remove ${firstName} ${lastName} from the roster?`;
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>{modalTitle}</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div>{modalMessage}</div>
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type="back" onClick={() => hideModal()}>
              {" "}
              {backButtonLabel}
            </Button>
            <Button
              type="delete"
              onClick={handleDeleteAthlete}
              disabled={isDeleteButtonDisabled}
            >
              Delete
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default DeleteAthlete;
