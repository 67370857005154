import axios from "axios";
import environment from "../environment/environment";
import moment from "moment";

const { API_URL } = environment;

const formatAthlete = (athleteFromServer) => {
  const formattedAthlete = {};
  for (const property in athleteFromServer) {
    if (property === "birthDate") {
      formattedAthlete[property] = athleteFromServer.birthDate
        ? moment(athleteFromServer.birthDate).format("MM/DD/YYYY")
        : "";
    } else {
      if (!athleteFromServer[property]) {
        formattedAthlete[property] = athleteFromServer[property] = "";
      } else {
        formattedAthlete[property] = athleteFromServer[property];
      }
    }
  }
  return formattedAthlete;
};

export const getAthletes = async (userId, rosterId) => {
  let apiUrl = null;
  if (rosterId) {
    apiUrl = `${API_URL}/rosterathlete/${userId}/${rosterId}`;
  } else {
    apiUrl = `${API_URL}/athlete/${userId}`;
  }

  const { data: athletes } = await axios.get(apiUrl);

  const formattedAthletes = [];
  athletes.forEach((athlete) => {
    formattedAthletes.push(formatAthlete(athlete));
  });

  return formattedAthletes;
};

export const getPaginatedAthletes = async (
  userId,
  pageNumber,
  athletesPerPage,
  rosterId,
  searchQuery,
  sort
) => {
  let apiUrl = `${API_URL}/athlete/${userId}/paginated?pageNumber=${pageNumber}&pageSize=${athletesPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`
  }

  const { data: paginatedAthleteData } = await axios.get(apiUrl);

  const { data: athletes, totalRecords } = paginatedAthleteData;

  const formattedAthletes = [];
  athletes.forEach((athlete) => {
    formattedAthletes.push(formatAthlete(athlete));
  });

  return { athletes: formattedAthletes, totalAthletes: totalRecords };
};

export const getExportedAthletes = async (
  userId,
  pageNumber,
  athletesPerPage,
  rosterId,
  searchQuery,
  sort
) => {
  let apiUrl = `${API_URL}/athlete/${userId}/export?pageNumber=${pageNumber}&pageSize=${athletesPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`
  }

  const { data: exportedAthletes } = await axios.get(apiUrl);

  const formattedExportAthletes = [];
  exportedAthletes.forEach((athlete) => {
    formattedExportAthletes.push(formatAthlete(athlete));
  });

  return formattedExportAthletes
};

export const getAthlete = async (userId, athleteId) => {
  const { data: athlete } = await axios.get(
    `${API_URL}/athlete/${userId}/${athleteId}`
  );
  return formatAthlete(athlete);
};

export const addAthlete = async (userId, athlete) => {
  const { data: addedAthlete } = await axios.post(
    `${API_URL}/athlete/${userId}`,
    athlete
  );
  return formatAthlete(addedAthlete);
};

export const updateAthleteService = async (
  userId,
  athleteId,
  updatedAthlete
) => {
  const { data: returnedUpdatedAthlete } = await axios.put(
    `${API_URL}/athlete/${userId}/${athleteId}`,
    updatedAthlete
  );
  return formatAthlete(returnedUpdatedAthlete);
};

export const deleteAthleteService = async (userId, athleteId) => {
  return await axios.delete(`${API_URL}/athlete/${userId}/${athleteId}`);
};

export const uploadAthletesService = async (userId, rosterId, excelFile) => {
  let apiUrl = `${API_URL}/athlete/upload/${userId}`;
  if (rosterId) {
    apiUrl += `?rosterId=${rosterId}`;
  }
  const { data: returnedAthletes } = await axios.post(apiUrl, excelFile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return returnedAthletes;
};
