import React, { useContext } from "react";
import Switch from "react-switch";
import { setMetricSettingService } from "../../../services/coachService";
import styles from "./MetricSettings.module.scss";
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";

const MetricSettings = () => {
  const { appState, appDispatch } = useContext(AppContext);
  const { state: authState } = useContext(AuthContext);
  const { metricSetting } = appState;

  const handleChangeMetric = async (checked, e, id) => {
    // const coach = await Auth.currentUserInfo();
    // const coachId = coach.attributes.sub;
    try {
      const { userId } = authState;
      await setMetricSettingService(userId, !metricSetting);
      appDispatch({ type: "SET_UNITS_SETTING", payload: !metricSetting });
    } catch (err) {}
  };

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.titleDescriptionContainer}>
        <div className={styles.title}>Metric</div>
        <div className={styles.description}>
          For each user account, you may choose which unit to view the biometric
          data of your athletes. All event results that are reported will remain
          in their original unit. For instance, a 60m Sprint will retain the 60m
          label regardless of this setting
        </div>
      </div>
      <div></div>
      <Switch
        id={1}
        onChange={handleChangeMetric}
        checked={metricSetting}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor={"#1d88f2"}
      />
    </div>
  );
};

export default MetricSettings;
