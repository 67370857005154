import React, { useContext } from "react";
import Detail from "../../../../components/detail/Detail";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import Button from "../../../../components/button/Button";
import styles from "./EpicIndexReportDetail.module.scss";
import { AuthContext } from "../../../../contexts/AuthContext";
import environment from "../../../../environment/environment";
import { ReportsContext } from "../../../../contexts/ReportsContext";

const { API_URL } = environment;

const individualReportDetails = [
  { label: "First Name", property: "firstName" },
  { label: "Middle Name", property: "middleName" },
  { label: "Last Name", property: "lastName" },
  { label: "Testing Date", property: "testingDate" },
  { label: "Sport", property: "sport" },
  { label: "Position", property: "position" },
  { label: "Height (IN.)", property: "height" },
  { label: "Weight (LBS)", property: "weight" },
  { label: "Reach (IN.)", property: "reach" },
  { label: "Wingspan (IN.)", property: "wingSpan" },
  { label: "10 Yard Dash", property: "tenYardDashTime" },
  { label: "Pro Agility", property: "proAgilityTime" },
  { label: "Vertical", property: "verticalTime" },
  { label: "10 Yard Dash Index", property: "dashIndex" },
  { label: "Pro Agility Index", property: "proAgilityIndex" },
  { label: "Vertical Index", property: "verticalIndex" },
  { label: "Overall Index", property: "overallIndex" },
];

const teamReportDetails = [
  { label: "Roster Name", property: "rosterName" },
  { label: "Average Dash Index", property: "averageDashIndex" },
  { label: "Average Proagility Index", property: "averageProAgilityIndex" },
  { label: "Average Vertical Index", property: "averageVerticalIndex" },
  { label: "Average Epic Index", property: "averageEpicIndex" },
];

const TEAM_ACTIVE_REPORT_TYPE = 2;

const EpicIndexReportDetail = ({ report }) => {
  const { replaceModal, hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { reportsState } = useContext(ReportsContext);
  const { userId } = authState;
  const { activeReportType } = reportsState;
  const isTeamReport = activeReportType === TEAM_ACTIVE_REPORT_TYPE;
  const reportDetails = isTeamReport
    ? teamReportDetails
    : individualReportDetails;

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Report Detail</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          {reportDetails.map((reportDetail) => {
            const { label, property } = reportDetail;
            return <Detail label={label} value={report[property]} />;
          })}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={() => {
                let reportUrl = `${API_URL}/epicindex/${userId}/${report.id}/render`;
                if (isTeamReport) {
                  reportUrl = `${API_URL}/teamepicindex/${userId}/${report.id}/render`;
                }
                window.open(reportUrl);
              }}
            >
              Generate PDF Report
            </Button>
            <Button
              className={styles.backButton}
              type={"back"}
              onClick={() => hideModal()}
            >
              Back
            </Button>
            <Button
              type={"delete"}
              onClick={() => {
                replaceModal("DeleteEpicIndexReport", { reportId: report.id });
              }}
            >
              Delete Report
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default EpicIndexReportDetail;
