import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./ThirdPartyConfirm.module.scss";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { ModalCardFooter } from "bloomer/lib/components/Modal/Card/ModalCardFooter";
import Button from "../../../../components/button/Button";
import { AuthContext } from "../../../../contexts/AuthContext";
import { setThirdPartyIntegrationService } from "../../../../services/thirdPartyIntegrationService";
import { AccountContext } from "../../../../contexts/AccountContext";

const ThidPartyConfirm = ({ enable, company }) => {
  const [progressMessage, setProgressMessage] = useState(null);
  const { hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { setThirdPartyIntegration } = useContext(AccountContext);

  let message = null;
  let note = null;
  if (enable) {
    message = `Are you sure you want to integrated with ${company.name}`;
    note =
      "Note: You are allowing this entity access to all your athlete and result data";
  } else {
    message = `Are you sure you want to disable integration with ${company.name}`;
    note = `Note: You are denying ${company.name} access to all your athlete and result data`;
  }

  const handleIntegration = async () => {
    try {
      setProgressMessage({
        isLoading: true,
        message: enable
          ? `Integrating with ${company.name}`
          : `Disabling integration with ${company.name}`,
        isError: false,
      });
      const { userId } = authState;
      await setThirdPartyIntegrationService(userId, company.id, enable);
      setThirdPartyIntegration(company.id, enable);
      setProgressMessage({
        isLoading: false,
        message: enable
          ? `Successfully integrated with ${company.name}`
          : `Successfully disabled integration with ${company.name}`,
        isError: false,
      });
    } catch (err) {
      setProgressMessage({
        isLoading: false,
        message: `Failed ${
          enable
            ? `integration with ${company.name}`
            : `Disabling integration with ${company.name}`
        }, Please Try Again`,
        isError: true,
      });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Integration</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.contentContainer}>
            <div className={styles.message}>{message}</div>
            <div className={styles.note}>{note}</div>
          </div>
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              Cancel
            </Button>
            <Button type={enable ? null : "delete"} onClick={handleIntegration}>
              {enable ? "Integrate" : "Disable Integration"}
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default ThidPartyConfirm;
