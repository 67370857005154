import { exportToCsv } from "../exportCsv";
import moment from "moment-timezone";


const csvHeaders = [
  "Date",
  "Time",
  "Event Time",
  "First Name",
  "Middle Name",
  "Last Name",
  "Event",
  "Start/Approach Distance",
  "Split Time (Pro Agility)",
  "Split Time 1 ( Dash or Flying)",
  "Split Distance 1 ( Dash or Flying)",
  "Split Time 2 ( Dash or Flying)",
  "Split Distance 2 ( Dash or Flying)",
  "Split Time 3 ( Dash or Flying)",
  "Split Distance 3 ( Dash or Flying)",
  "Split Time 4 ( Dash or Flying)",
  "Split Distance 4 ( Dash or Flying)",
  "Split Time 5 ( Dash or Flying)",
  "Split Distance 5 ( Dash or Flying)",
  "Split Time 6 ( Dash or Flying)",
  "Split Distance 6 ( Dash or Flying)",
  "Final Time",
  "Final Distance ( Dash or Flying)",
  "Jump Distance",
  "VJ Touch Height",
  "Reaction Time",
  "Direction",
  "Lap Time 1",
  "Lap Time 2",
  "Lap Time 3",
  "Lap Time 4",
  "Lane ID",
  "Units",
  "Height",
  "Weight",
  "Shoe Size",
  "Wingspan",
  "Reach",
  "Hand Size",
  "Sport",
  "Position",
  "Third Party ID",
  "Graduation Year",
  "Zip Code",
  "Birthday",
  "Sex",
  "Badge ID",
  "Custom Field 1",
  "Custom Field 2",
  "Custom Field 3",
  "Custom Field 4",
];

export const exportResultsToCsv = (results) => {
  const formattedResults = results.map((result) => {
    let m = moment.utc(result.date);
    m.tz("America/Chicago");
    const date = m.format("MM/DD/YYYY");
    let time = m.format("HH:mm");
    time += ":00";
    console.log(time);
    let splitTime = "";
    let splitTime1 = "";
    let splitDistance1 = "";
    let splitTime2 = "";
    let splitDistance2 = "";
    let splitTime3 = "";
    let splitDistance3 = "";
    let splitTime4 = "";
    let splitDistance4 = "";
    let splitTime5 = "";
    let splitDistance5 = "";
    let splitTime6 = "";
    let splitDistance6 = "";
    let finalTime = "";
    let finalDistance = "";
    let jumpDistance = "";
    let direction = "";
    let reactionTime = "";
    let startDistance = "";
    let units = "";
    let height = "";
    let weight = "";
    let shoeSize = "";
    let wingSpan = "";
    let reach = "";
    let handSize = "";
    let sport = "";
    let position = "";
    let thirdPartyId = "";
    let graduationYear = "";
    let zipCode = "";
    let birthDate = "";
    let sex = "";
    let badgeId = "";
    let customField1 = "";
    let customField2 = "";
    let customField3 = "";
    let customField4 = "";
    let eventTime = "";
    let vjTouchHeight = "";
    let lapTime1 = "";
    let lapTime2 = "";
    let lapTime3 = "";
    let lapTime4 = "";

    const athlete = result.athlete;
    if (result.splitTime !== undefined && Array.isArray(result.splitTime)) {
      let splitTimes = result.splitTime;
      if (splitTimes[0] !== undefined) {
        if (splitTimes[0].time !== undefined)
          console.log(splitTimes[0])
          splitTime1 = splitTimes[0].time;
        if (splitTimes[0].distance !== undefined)
          splitDistance1 = splitTimes[0].distance;
      }

      if (splitTimes[1] !== undefined) {
        if (splitTimes[1].time !== undefined)
          splitTime2 = splitTimes[1].time;
        if (splitTimes[1].distance !== undefined)
          splitDistance2 = splitTimes[1].distance;
      }

      if (splitTimes[2] !== undefined) {
        if (splitTimes[2].time !== undefined)
          splitTime3 = splitTimes[2].time;
        if (splitTimes[2].distance !== undefined)
          splitDistance3 = splitTimes[2].distance;
      }

      if (splitTimes[3] !== undefined) {
        if (splitTimes[3].time !== undefined)
          splitTime4 = splitTimes[3].time;
        if (splitTimes[3].distance !== undefined)
          splitDistance4 = splitTimes[3].distance;
      }

      if (splitTimes[4] !== undefined) {
        if (splitTimes[4].time !== undefined)
          splitTime5 = splitTimes[4].time;
        if (splitTimes[4].distance !== undefined)
          splitDistance5 = splitTimes[4].distance;
      }

      if (splitTimes[5] !== undefined) {
        if (splitTimes[5].time !== undefined)
          splitTime6 = splitTimes[5].time;
        if (splitTimes[5].distance !== undefined)
          splitDistance6 = splitTimes[5].distance;
      }
    } else if (result.splitTime !== undefined) {
      splitTime = result.splitTime;
    }
    if (result.splitTime1 !== undefined) {
      splitTime1 = result.splitTime1;
    }
    if (result.splitTime2 !== undefined) {
      splitTime2 = result.splitTime2;
    }
    if (result.splitTime3 !== undefined) {
      splitTime3 = result.splitTime3;
    }
    if (result.splitTime4 !== undefined) {
      splitTime4 = result.splitTime4;
    }
    if (result.splitTime5 !== undefined) {
      splitTime5 = result.splitTime5;
    }
    if (result.subtype !== undefined) {
      splitTime = result.subtype;
    }
    if (result.finalTime !== undefined && result.finalTime instanceof Object) {
      finalTime = result.finalTime.time;
      if (result.finalTime.distance !== undefined)
        finalDistance = result.finalTime.distance;
    } else if (result.finalTime !== undefined)
      finalTime = result.finalTime;

    if (result.jumpDistance !== undefined) {
      jumpDistance = result.jumpDistance;
    }

    if (result.reactionTime !== undefined) {
      reactionTime = result.reactionTime;
    }
    if (result.isLeft !== undefined) {
      direction = result.isLeft ? "Left" : "Right";
    }
    if (result.startDistance) {
      startDistance = result.startDistance;
    }
    if (result.isMetric === true) {
      units = "Metric";
    } else if (result.isMetric === false) {
      units = "Imperial";
    }

    if (athlete.height) {
      height = athlete.height;
    }
    if (athlete.weight) {
      weight = athlete.weight;
    }
    if (athlete.shoeSize) {
      shoeSize = athlete.shoeSize;
    }
    if (athlete.wingSpan) {
      wingSpan = athlete.wingSpan;
    }
    if (athlete.reach) {
      reach = athlete.reach;
    }
    if (athlete.handSize) {
      handSize = athlete.handSize;
    }
    if (athlete.sport) {
      handSize = athlete.sport;
    }
    if (athlete.position) {
      position = athlete.position;
    }
    if (athlete.thirdPartyId) {
      thirdPartyId = athlete.thirdPartyID;
    }
    if (athlete.graduationYear) {
      graduationYear = athlete.graduationYear;
    }
    if (athlete.zipCode) {
      zipCode = athlete.zipCode;
    }
    if (athlete.birthDate) {
      birthDate = moment(athlete.birthDate).format("MM/DD/YYYY");
    }
    if (athlete.sex) {
      sex = athlete.sex;
    }
    if (athlete.badgeId) {
      badgeId = athlete.badgeID;
    }
    if (athlete.customField1) {
      customField1 = athlete.customField1;
    }
    if (athlete.customField2) {
      customField2 = athlete.customField2;
    }
    if (athlete.customField3) {
      customField3 = athlete.customField3;
    }
    if (athlete.customField4) {
      customField4 = athlete.customField4;
    }
    if (athlete.eventTime) {
      eventTime = result.eventTime;
    }

    if (result.event === "Vertical") {
      vjTouchHeight = athlete.reach + result.jumpDistance;
    }
    if (result.time1 !== undefined) {
      lapTime1 = result.time1;
    }
    if (result.time2 !== undefined) {
      lapTime2 = result.time2;
    }
    if (result.time3 !== undefined) {
      lapTime3 = result.time3;
    }
    if (result.time4 !== undefined) {
      lapTime4 = result.time4;
    }
    
    return [
      date,
      time,
      eventTime,
      result.firstName,
      result.middleName,
      result.lastName,
      result.event,
      startDistance,
      splitTime,
      splitTime1,
      splitDistance1,
      splitTime2,
      splitDistance2,
      splitTime3,
      splitDistance3,
      splitTime4,
      splitDistance4,
      splitTime5,
      splitDistance5,
      splitTime6,
      splitDistance6,
      finalTime,
      finalDistance,
      jumpDistance,
      vjTouchHeight,
      reactionTime,
      direction,
      lapTime1,
      lapTime2,
      lapTime3,
      lapTime4,
      result.laneID ? result.laneID : "",
      units,
      height,
      weight,
      shoeSize,
      wingSpan,
      reach,
      handSize,
      sport,
      position,
      thirdPartyId,
      graduationYear,
      zipCode,
      birthDate,
      sex,
      badgeId,
      customField1,
      customField2,
      customField3,
      customField4,
    ];
  });
  formattedResults.unshift(csvHeaders);
  exportToCsv(formattedResults, "results");
};
