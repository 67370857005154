import React, { useContext } from "react";
import Button from "../../../components/button/Button";
import { ModalContext } from "../../../contexts/ModalContext";

import styles from "./DeleteData.module.scss";

const DeleteData = () => {
  const { showModal } = useContext(ModalContext);
  return (
    <div className={styles.settingsContainer}>
      <div className={styles.titleDescriptionContainer}>
        <div className={styles.title}>Delete Data</div>
        <div className={styles.description}>
          Permantently delete any data pertaining to this account
        </div>
      </div>
      <div>
          <Button className={styles.deleteButton} type="delete" onClick={() => showModal('DeleteDataModal')}>X</Button>
      </div>
    </div>
  );
};

export default DeleteData;