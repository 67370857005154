import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./DeleteResult.module.scss";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { ModalCardFooter } from "bloomer/lib/components/Modal/Card/ModalCardFooter";
import Button from "../../../../components/button/Button";
import { AuthContext } from "../../../../contexts/AuthContext";
import { deleteResultService, getPaginatedEvents } from "../../../../services/resultsService";
import { ResultsContext } from "../../../../contexts/ResultsContext";

const DeleteResult = ({ resultId }) => {
  const [progressMessage, setProgressMessage] = useState(null);
  const { hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { resultsDispatch, resultsState } = useContext(ResultsContext);
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [backButtonLabel, setBackButtonLabel] = useState("Cancel");

  const handleDeleteResult = async () => {
    try {
      setProgressMessage({
        isLoading: true,
        message: "Deleting Result",
        isError: false,
      });
      const { userId } = authState;
      await deleteResultService(userId, resultId);
      const {
        currentPage,
        resultsPerPage,
        searchQuery,
        startDateFilter,
        endDateFilter,
        activeRosterFilter,
        sort,
      } = resultsState;
      const fetchedPaginatedResults = await getPaginatedEvents(
        userId,
        currentPage,
        resultsPerPage,
        activeRosterFilter,
        searchQuery,
        sort,
        startDateFilter,
        endDateFilter
      );
      const { results, totalResults } = fetchedPaginatedResults;
      resultsDispatch({
        type: "SET_RESULTS",
        payload: {
          results,
          totalResults,
        },
      });
      setProgressMessage({
        isLoading: false,
        message: "Sucessfully Deleted Result",
        isError: false,
      });
      setDeleteButtonDisabled(true);
      setBackButtonLabel("Exit");
    } catch (err) {
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed Deleting Result, Please Try Again",
            isError: true,
          });
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Delete Result</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.deleteMessage}>
            Are you sure you want to delete this result?
          </div>
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              {backButtonLabel}
            </Button>
            <Button
              type={"delete"}
              onClick={handleDeleteResult}
              disabled={isDeleteButtonDisabled}
            >
              Delete
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default DeleteResult;
