import React from "react";
import styles from "./Input.module.scss";
import classNames from "classnames";

const Input = ({ id, type, value, onChange, centeredText, readOnly }) => {
  let classes = centeredText ? classNames(styles.input, styles.centeredText) : styles.input
  classes = readOnly ? classNames(styles.inputReadOnly, classes) : classes
  return (
    <input
      id={id}
      className={classes}
      type={type}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
    />
  );
};

export default Input;
