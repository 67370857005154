import React from "react";

const Select = ({ size, options, value, onOptionsChange }) => {
  
  let className = "select is-normal"
  if (size === "small") {
    className = "select is-small"
  }

  return (
    <div className={className}>
    <select
      onChange={(e) => {
        e.preventDefault();
        onOptionsChange(e.target.value);
      }}
      defaultValue={value}
    >
      {options.map((option) => {
        return (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        );
      })}
    </select>
  </div>
  );
};

export default Select;
