import { addWorksheet, exportToExcel } from "../excelUtils";
import moment from "moment-timezone";
import * as XLSX from "xlsx";

export const exportResultsToExcel = (results) => {
  const workbook = XLSX.utils.book_new();
  const vertical = [];
  const approachVertical = [];
  const proAgility = [];
  const lDrill = [];
  const broadJump = [];
  const reactAgility = [];
  const reactShuttle = [];
  const tTest = [];
  const _505AgilityTest = [];
  const lifting = [];
  const lap = [];
  const dash = [];
  const flying = [];

  results.forEach((result) => {
    const { athlete } = result;
    const dateCst = moment.utc(result.date).tz("America/Chicago");
    const formattedDate = dateCst.format("MM/DD/YYYY");
    const formattedTimeCollected = dateCst.format("HH:mm") + ":00";

    const event = {
      Date: formattedDate,
      "Time Collected": formattedTimeCollected,
      LaneID: result.laneID
    };

    const bioInfo = {
      "First Name": athlete.firstName,
      "Middle Name": athlete.middleName,
      "Last Name": athlete.lastName,
      Height: athlete.height,
      Weight: athlete.weight,
      "Shoe Size": athlete.shoeSize,
      Wingspan: athlete.wingSpan,
      Reach: athlete.reach,
      "Hand Size": athlete.handSize,
      Sport: athlete.sport,
      Position: athlete.position,
      "Third Party ID": athlete.thirdPartyID,
      "Graduation Year": athlete.graduationYear,
      "Zip Code": athlete.zipCode,
      Birthday: athlete.birthDate
        ? moment(athlete.birthDate).format("MM/DD/YYYY")
        : null,
      Sex: athlete.sex,
      "Badge ID": athlete.badgeID,
      "Custom Field 1": athlete.customField1,
      "Custom Field 2": athlete.customField2,
      "Custom Field 3": athlete.customField3,
      "Custom Field 4": athlete.customField4,
    };

    switch (result.event) {
      case "Vertical": {
        vertical.push({
          ...event,
          "Jump Distance": result.jumpDistance,
          ...bioInfo,
        });
        break;
      }
      case "ProAgility": {
        proAgility.push({
          ...event,
          "Split Time": result.splitTime,
          "Final Time": result.finalTime,
          ...bioInfo,
        });
        break;
      }
      case "LDrill": {
        lDrill.push({ ...event, "Final Time": result.finalTime, ...bioInfo });
        break;
      }
      case "ApproachVertical": {
        approachVertical.push({
          ...event,
          "Jump Distance": result.jumpDistance,
          ...bioInfo,
        });
        break;
      }
      case "BroadJump": {
        broadJump.push({
          ...event,
          "Jump Distance": result.jumpDistance,
          ...bioInfo,
        });
        break;
      }
      case "ReactAgility": {
        reactAgility.push({
          ...event,
          "Split Time": result.splitTime,
          "Final Time": result.finalTime,
          Direction: result.isLeft ? "Left" : "Right",
          ...bioInfo
        });
        break;
      }
      case "ReactShuttle": {
        reactShuttle.push({
          ...event,
          "Split Time": result.splitTime,
          "Final Time": result.finalTime,
          "Reaction Time": result.reactionTime,
          Direction: result.isLeft ? "Left" : "Right",
          ...bioInfo
        });
        break;
      }
      case "TTest": {
        tTest.push({ ...event, "Final Time": result.finalTime, ...bioInfo });
        break;
      }
      case "505AgilityTest": {
        _505AgilityTest.push({ ...event, "Final Time": result.finalTime, ...bioInfo });
        break;
      }
      case "Lifting": {
        lifting.push({
          ...event,
          Lift: result.lift,
          Weight: result.weight,
          Reps: result.reps,
          ...bioInfo
        });
        break;
      }
      case "Lap": {
        lap.push({
          ...event,
          "Time 1": result.time1,
          "Time 2": result.time2,
          "Time 3": result.time3,
          "Time 4": result.time4,
          ...bioInfo
        });
        break;
      }
      case "Dash": {
        const { splitTime, finalTime } = result;
        dash.push({
          ...event,
          "Split Time 1": splitTime[0]?.time,
          "Split Distance 1": splitTime[0]?.distance,
          "Split Time 2": splitTime[1]?.time,
          "Split Distance 2": splitTime[1]?.distance,
          "Split Time 3": splitTime[2]?.time,
          "Split Distance 3": splitTime[2]?.distance,
          "Split Time 4": splitTime[3]?.time,
          "Split Distance 4": splitTime[3]?.distance,
          "Final Time": finalTime?.time,
          "Final Distance": finalTime?.distance,
          ...bioInfo
        });
        break;
      }
      case "Flying": {
        const { splitTime, finalTime, startDistance } = result;
        flying.push({
          ...event,
          "Start Distance": startDistance,
          "Split Time 1": splitTime[0]?.time,
          "Split Distance 1": splitTime[0]?.distance,
          "Split Time 2": splitTime[1]?.time,
          "Split Distance 2": splitTime[1]?.distance,
          "Split Time 3": splitTime[2]?.time,
          "Split Distance 3": splitTime[2]?.distance,
          "Split Time 4": splitTime[3]?.time,
          "Split Distance 4": splitTime[3]?.distance,
          "Final Time": finalTime?.time,
          "Final Distance": finalTime?.distance,
          ...bioInfo
        });
        break;
      }
      default:
        break;
    }
  });

  addWorksheet(workbook, "Dash", dash);
  addWorksheet(workbook, "Flying", flying);
  addWorksheet(workbook, "Pro Agility", proAgility);
  addWorksheet(workbook, "Approach Vertical", approachVertical);
  addWorksheet(workbook, "Vertical Jump", vertical);
  addWorksheet(workbook, "Broad Jump", broadJump);
  addWorksheet(workbook, "L Drill", lDrill);
  addWorksheet(workbook, "React Agility", reactAgility);
  addWorksheet(workbook, "React Shuttle", reactShuttle);
  addWorksheet(workbook, "T Test", tTest);
  addWorksheet(workbook, "505 Agility Test", _505AgilityTest);
  addWorksheet(workbook, "Lifting", lifting);
  addWorksheet(workbook, "Lap", lap);

  exportToExcel("results", workbook);
};
