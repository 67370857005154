import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { AuthContext } from "../../../../contexts/AuthContext";
import { addAthlete, getPaginatedAthletes } from "../../../../services/athleteService";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import TextField from "../../../textfield/TextField";
import Button from "../../../../components/button/Button";
import { addNewAthleteToRoster } from "../../../../services/rosterService";
import styles from "./AddAthlete.module.scss";
import { AppContext } from "../../../../contexts/AppContext";
import {
  formatAthleteForBackendService,
  getAthleteFields,
} from "../athleteUtils";
import { useForm } from "../../../../hooks/useForm";
import athleteValidations from "../athleteValidations";
import { isCompositeComponent } from "react-dom/test-utils";

const initialAthleteValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  badgeID: "",
  thirdPartyID: "",
  sport: "",
  position: "",
  height: "",
  weight: "",
  reach: "",
  handSize: "",
  wingSpan: "",
  shoeSize: "",
  zipCode: "",
  graduationYear: "",
  birthDate: "",
  sex: "",
  customField1: "",
  customField2: "",
  customField3: "",
  customField4: "",
};

const AddAthlete = ({ roster }) => {
  const { hideModal } = useContext(ModalContext);
  const [progressMessage, setProgressMessage] = useState(null);
  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch, athletesState } = useContext(AthletesContext);
  const { appState } = useContext(AppContext);
  const { metricSetting } = appState;
  const athleteFields = getAthleteFields(metricSetting);
  const [isAddButtonDisabled, setAddButtonDisabled] = useState(false);
  const [backButtonLabel, setBackButtonLabel] = useState("Cancel");

  const { values, errors, bindField, isValid } = useForm({
    validations: athleteValidations,
    initialValues: initialAthleteValues,
  });

  const handleAddAthlete = async () => {
    setProgressMessage({
      isLoading: true,
      message: "Adding Athlete...",
      isError: false,
    });
    if (!isValid()) {
      setProgressMessage({
        isLoading: false,
        message:
          "Failed adding athlete, you have entered invalid data for 1 or more fields.",
        isError: true,
      });
      return;
    }
    try {
      const athlete = formatAthleteForBackendService(values);
      const { userId } = authState;
      if (roster) {
        await addNewAthleteToRoster(userId, roster, athlete);
      } else {
        await addAthlete(userId, athlete);
      }
      const { activeRoster, currentPage, athletesPerPage, searchQuery, sort } =
        athletesState;
      const { athletes, totalAthletes } = await getPaginatedAthletes(
        userId,
        currentPage,
        athletesPerPage,
        activeRoster,
        searchQuery,
        sort
      );
      athletesDispatch({
        type: "FETCH_ATHLETES",
        payload: { athletes, totalAthletes },
      });
      setProgressMessage({
        isLoading: false,
        message: "Successfully added athlete",
        isError: false,
      });
      setAddButtonDisabled(true);
      setBackButtonLabel("Exit");
      return;
    } catch (err) {
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed adding roster, please try again",
            isError: true,
          });
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  const modalTitle = roster ? "Add New Athlete to Roster" : "Add New Athlete";

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>{modalTitle}</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div>
            {athleteFields.map((field) => {
              const { label, property } = field;
              const { value, onChange } = bindField(property);
              return (
                <TextField
                  label={label}
                  value={value}
                  onChange={(e) => onChange(e)}
                  helperText={errors[property]}
                />
              );
            })}
          </div>
        </ModalCardBody>
        <ModalCardFooter className={styles.modalFooter}>
          {progressMessageComponent}
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              {backButtonLabel}
            </Button>
            <Button onClick={handleAddAthlete} disabled={isAddButtonDisabled}>
              Add
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default AddAthlete;
