import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { AuthContext } from "../../../../contexts/AuthContext";
import { updateAthleteService } from "../../../../services/athleteService";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import TextField from "../../../textfield/TextField";
import Button from "../../../../components/button/Button";
import { AppContext } from "../../../../contexts/AppContext";
import athleteValidations from "../athleteValidations";
import styles from "./EditAthlete.module.scss";
import {
  getAthleteFields,
  formatAthleteForBackendService,
} from "../athleteUtils";
import { useForm } from "../../../../hooks/useForm";

const EditAthlete = ({ athlete }) => {
  const { hideModal } = useContext(ModalContext);
  const [progressMessage, setProgressMessage] = useState(null);
  const { values, errors, bindField, isValid } = useForm({
    validations: athleteValidations,
    initialValues: athlete,
  });

  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch } = useContext(AthletesContext);
  const { appState } = useContext(AppContext);
  const { metricSetting } = appState;
  const athleteFields = getAthleteFields(metricSetting);
  const [isModifyButtonDisabled, setIsModifiedButtonDisabled] = useState(true);
  const [backButtonLabel, setBackButtonLabel] = useState("Cancel");

  const handleModifyAthlete = async () => {
    setProgressMessage({
      isLoading: true,
      message: "Modifying Athlete...",
      isError: false,
    });

    if (!isValid()) {
      setProgressMessage({
        isLoading: false,
        message:
          "Failed modifying athlete, you have entered invalid data for 1 or more fields.",
        isError: true,
      });
      return;
    }
    const modifiedAthlete = formatAthleteForBackendService(values);
    try {
      const { userId } = authState;
      const returnedModifiedAthlete = await updateAthleteService(
        userId,
        athlete.id,
        modifiedAthlete
      );
      athletesDispatch({
        type: "UPDATE_ATHLETE",
        payload: returnedModifiedAthlete,
      });
      setProgressMessage({
        isLoading: false,
        message: "Successfully modified athlete",
        isError: false,
      });
      setIsModifiedButtonDisabled(true);
      setBackButtonLabel("Exit");
    } catch (err) {
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed adding roster, please try again",
            isError: true,
          });
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }
  console.log(athlete);
  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Edit Athlete</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          {athleteFields.map((field) => {
            const { label, property } = field;
            const { value, onChange } = bindField(property);
            const isReadOnly =
              property === "firstName" ||
              property === "middleName" ||
              property === "lastName";
            return (
              <TextField
                label={label}
                value={value}
                readOnly={isReadOnly}
                onChange={(e) => {
                  if (isModifyButtonDisabled) {
                    setIsModifiedButtonDisabled(false);
                  }
                  onChange(e);
                }}
                helperText={errors[property]}
              />
            );
          })}
        </ModalCardBody>
        <ModalCardFooter className={styles.modalFooter}>
          {progressMessageComponent}
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              {backButtonLabel}
            </Button>
            <Button
              onClick={handleModifyAthlete}
              disabled={isModifyButtonDisabled}
            >
              Modify
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default EditAthlete;
