import React, { createContext, useState } from "react";

const AccountContext = createContext(null);

const AccountProvider = ({ children }) => {
  const [accountInfo, setAccountInfo] = useState(null);

  const updateAccountInfo = (updatedAccountInfo) => {
    setAccountInfo({ ...accountInfo, ...updatedAccountInfo });
  };

  const setThirdPartyIntegration = (companyId, isIntegrated) => {
    const { thirdPartyIntegration } = accountInfo;
    const newThirdPartyIntegration = [...thirdPartyIntegration];
    newThirdPartyIntegration.forEach((thirdPartyCompany) => {
      if (thirdPartyCompany.id === companyId) {
        thirdPartyCompany.isIntegrated = isIntegrated;
      }
    });
    console.log(newThirdPartyIntegration);
    setAccountInfo({
      ...accountInfo,
      thirdPartyIntegration: newThirdPartyIntegration,
    });
  };

  return (
    <AccountContext.Provider
      value={{
        accountInfo,
        setAccountInfo,
        updateAccountInfo,
        setThirdPartyIntegration,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };
