import React, { useState, useEffect, useContext } from "react";
import Button from "../../components/button/Button";
import FetchMessage from "../../components/fetchMessage/FetchMessage";
import SearchBar from "../../components/searchbar/SearchBar";
import Select from "../../components/select/Select";
import Table from "../../components/table/Table";
import { AuthContext } from "../../contexts/AuthContext";
import { ModalContext } from "../../contexts/ModalContext";
import { AthletesContext } from "../../contexts/AthletesContext";
import { getRosters } from "../../services/rosterService";
import styles from "./Athletes.module.scss";
import {
  getAthletes,
  getExportedAthletes,
  getPaginatedAthletes,
} from "../../services/athleteService";
import { exportAthletes } from "../../export/exportAthletes";
import { AppContext } from "../../contexts/AppContext";
import _ from "lodash";
import { AccountContext } from "../../contexts/AccountContext";
import PaginatedTable from "../../containers/paginatedtable/PaginatedTable";

const tableColumns = [
  { path: "firstName", label: "First Name" },
  { path: "middleName", label: "Middle Name" },
  { path: "lastName", label: "Last Name" },
];

const Athletes = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { state: authState } = useContext(AuthContext);
  const { appDispatch } = useContext(AppContext);
  const { showModal } = useContext(ModalContext);
  const { athletesState, athletesDispatch } = useContext(AthletesContext);
  const {
    activeRoster,
    rosters,
    athletes,
    sortColumn,
    currentPage,
    athletesPerPage,
    totalAthletes,
    searchQuery,
    sort,
  } = athletesState;
  const { userId } = authState;
  const { accountInfo } = useContext(AccountContext);
  const { athleteSpots } = accountInfo;

  useEffect(() => {
    const fetchRosters = async () => {
      const rosters = await getRosters(userId);
      athletesDispatch({ type: "FETCH_ROSTERS", payload: rosters });
    };
    fetchRosters();
    appDispatch({ type: "SET_CURRENT_PAGE_TITLE", payload: "Athletes" });
  }, [appDispatch, athletesDispatch, userId]);

  useEffect(() => {
    const fetchAthletes = async (activeRoster) => {
      const { athletes, totalAthletes } = await getPaginatedAthletes(
        userId,
        currentPage,
        athletesPerPage,
        activeRoster,
        searchQuery,
        sort
      );
      athletesDispatch({
        type: "FETCH_ATHLETES",
        payload: { athletes, totalAthletes },
      });
    };
    fetchAthletes(activeRoster);
  }, [
    activeRoster,
    athletesDispatch,
    userId,
    currentPage,
    athletesPerPage,
    searchQuery,
    sort,
  ]);

  if (!athletes || !rosters) {
    return <FetchMessage message={"Fetching Athletes"} />;
  }

  let removeRosterButton = null;
  if (activeRoster !== -1) {
    removeRosterButton = (
      <Button
        type={"delete"}
        onClick={() => {
          const roster = rosters.find((roster) => roster.id === activeRoster);
          if (roster) {
            showModal("DeleteRoster", { roster });
          }
        }}
      >
        Remove Roster
      </Button>
    );
  }

  const handleAthletesPerPageChange = (currentPage, athletesPerPage) => {
    athletesDispatch({
      type: "CHANGE_ATHLETES_PER_PAGE",
      payload: athletesPerPage,
    });
  };

  const handlePageChange = (page) => {
    athletesDispatch({ type: "CHANGE_CURRENT_PAGE", payload: page });
  };

  const handleSearch = (searchTerm) => {};

  const totalAthletesMessage =
    activeRoster === -1 ? `Total Athletes` : `Total Athletes on Roster`;

  return (
    <>
      <div
        className={styles.centeredStatsContainer}
      >{`Roster Spots Available: ${athleteSpots}`}</div>
      <div
        className={styles.centeredStatsContainer}
      >{`${totalAthletesMessage}: ${athletes.length}`}</div>

      <SearchBar
        query={searchQuery}
        onChange={(query) => {
          athletesDispatch({ type: "SET_SEARCH_QUERY", payload: query });
        }}
        onSearch={(query) =>
          athletesDispatch({ type: "SET_SEARCH_QUERY", payload: query })
        }
      />

      <div className={styles.actionContainer}>
        <Select
          options={rosters}
          value={activeRoster}
          onOptionsChange={(roster) =>
            athletesDispatch({
              type: "CHANGE_ROSTER",
              payload: parseInt(roster),
            })
          }
        />
        <Button
          className={styles.rosterButton}
          onClick={() => {
            const roster = rosters.filter(
              (roster) => roster.id === activeRoster
            )[0];
            activeRoster === -1
              ? showModal("AddAthlete")
              : showModal("AddRosterAthlete", { roster });
          }}
        >
          + Athlete
        </Button>
        <Button
          onClick={() => {
            showModal("AddRoster");
          }}
        >
          + Roster
        </Button>
        {removeRosterButton}
        <Button
          onClick={() => {
            showModal("UploadAthletes");
          }}
        >
          Upload Athletes
        </Button>
        <Button
          onClick={async () => {
            const exportedAthletes = await getExportedAthletes(
              userId,
              currentPage,
              athletesPerPage,
              activeRoster,
              searchQuery,
              sort
            );
            if (activeRoster !== -1) {
              const roster = rosters.find(
                (roster) => roster.id === activeRoster
              );
              exportAthletes(exportedAthletes, roster.name);
            } else {
              exportAthletes(exportedAthletes);
            }
          }}
        >
          Export Athletes
        </Button>
      </div>
      <PaginatedTable
        columns={tableColumns}
        data={athletes}
        sortColumn={sortColumn}
        onSort={(sortColumn) => {
          athletesDispatch({
            type: "SET_SORT_COLUMN",
            payload: sortColumn,
          });
        }}
        total={totalAthletes}
        pageSize={athletesPerPage}
        onRowClick={(athlete) => showModal("AthleteDetail", { athlete })}
        onShowSizeChange={handleAthletesPerPageChange}
        onPageChange={handlePageChange}
        onGotoChange={handlePageChange}
      />
    </>
  );
};

export default Athletes;
