import React, { useState, useContext, useEffect } from "react";
import {
  Checkbox,
  Delete,
  Field,
  Label,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  Select,
  TextArea,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./AddEpicIndexReport.module.scss";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { ModalCardFooter } from "bloomer/lib/components/Modal/Card/ModalCardFooter";
import Button from "../../../../components/button/Button";
import { AuthContext } from "../../../../contexts/AuthContext";
import { getAthletes } from "../../../../services/athleteService";
import FetchMessage from "../../../../components/fetchMessage/FetchMessage";
import { getUserService } from "../../../../services/coachService";
import { AccountContext } from "../../../../contexts/AccountContext";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePond, registerPlugin } from "react-filepond";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { generateEpicIndexReportService, getPaginatedReports } from "../../../../services/reportsService";
import environment from "../../../../environment/environment";
import { ReportsContext } from "../../../../contexts/ReportsContext";

const { API_URL } = environment;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const AddEpicIndexReport = ({ athlete }) => {
  const [reportLogo, setReportLogo] = useState([]);
  const [progressMessage, setProgressMessage] = useState(null);
  const { hideModal, replaceModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { accountInfo, setAccountInfo } = useContext(AccountContext);
  const { reportsState, reportsDispatch } = useContext(ReportsContext);
  const [isFetching, setIsFetching] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isRecentResultsChecked, setIsRecentResultsChecked] = useState(true);
  const [generalNotes, setGeneralNotes] = useState("");
  console.log(`beginning ${isRecentResultsChecked}`);
  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const { userId } = authState;
        if (!accountInfo) {
          const fetchedAccountInfo = await getUserService(userId);
          setAccountInfo(fetchedAccountInfo);
          setIsFetching(false);
        }
      } catch {}
    };

    if (!accountInfo) {
      fetchAccountInfo();
    } else {
      setIsFetching(false);
    }
  }, [accountInfo, authState, setAccountInfo]);

  useEffect(() => {
    if (accountInfo) {
      const { reportLogoUri } = accountInfo;
      if (reportLogoUri) {
        fetch(accountInfo.reportLogoUri)
          .then((response) => response.blob())
          .then((myBlob) => setReportLogo([myBlob]));
      }
    }
  }, [accountInfo]);

  const addReport = async () => {
    const { userId } = authState;
    setProgressMessage({
      isLoading: true,
      message: "Generating report",
      isError: false,
    });
    if ((startDate || endDate) && isRecentResultsChecked) {
      setProgressMessage({
        isLoading: false,
        message:
          "You cannot pick a date range and take the most recent results",
        isError: true,
      });
      return;
    }
    if (!isRecentResultsChecked && (!startDate || !endDate)) {
      console.log(isRecentResultsChecked);
      if (!startDate || !endDate) {
        setProgressMessage({
          isLoading: false,
          message:
            "If you do not wish to pick the most recent results, you must select a date range",
          isError: true,
        });
        return;
      }
    }
    try {
      let startDateISO = null;
      let endDateISO = null;
      if (startDate && endDate) {
        startDateISO = moment(startDate).toISOString();
        endDateISO = moment(endDate).toISOString();
      }
      const report = await generateEpicIndexReportService(
        userId,
        athlete.id,
        generalNotes,
        startDateISO,
        endDateISO,
        reportLogo[0]
      );
      window.open(`${API_URL}/epicindex/${userId}/${report.id}/render`);

      const {
        currentPage,
        reportsPerPage,
        searchQuery,
        sort,
        activeRosterFilter,
        startDateFilter,
        endDateFilter,
      } = reportsState;

      const paginatedReportData = await getPaginatedReports(
        userId,
        currentPage,
        reportsPerPage,
        activeRosterFilter,
        searchQuery,
        sort,
        startDateFilter,
        endDateFilter
      );
      const { reports, totalReports } = paginatedReportData;
      reportsDispatch({
        type: "SET_REPORTS",
        payload: { reports, totalReports },
      });

      setProgressMessage({
        isLoading: false,
        message:
          "Successfully generated report!",
        isError: false,
      });
    } catch (err) {
      console.log(err);
      const { status, data } = err.response;
      let errorMessage = "network error, please try again!";
      if (status === 400) {
        const { hasReportSpots, errors } = data;
        if (!hasReportSpots) {
          setProgressMessage({
            isLoading: false,
            message:
              "You do not have enough report spots, showing a blank report",
            isError: true,
          });
          window.open(`${API_URL}/epicindex/${userId}/renderblank`);
          return;
        } else {
          if (errors) {
            errorMessage =
              "The athlete does not have the following biometrics or events:";
            errors.forEach((error) => {
              errorMessage += `${error} \n`;
            });
            setProgressMessage({
              isLoading: false,
              message: errorMessage,
              isError: true,
            });
            return;
          }
        }
      }
      setProgressMessage({
        isLoading: false,
        message: errorMessage,
        isError: true,
      });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Add Epic Index Report</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <ModalContents
            isFetching={isFetching}
            accountInfo={accountInfo}
            reportLogo={reportLogo}
            setReportLogo={setReportLogo}
            startDate={startDate}
            endDate={endDate}
            athlete={athlete}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            isRecentResultsChecked={isRecentResultsChecked}
            setIsRecentResultsChecked={setIsRecentResultsChecked}
            generalNotes={generalNotes}
            setGeneralNotes={setGeneralNotes}
          />
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button
              type={"back"}
              onClick={() => replaceModal("SelectReportAthlete")}
            >
              Back
            </Button>
            <Button onClick={addReport}>Generate</Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

const ModalContents = ({
  isFetching,
  accountInfo,
  reportLogo,
  athlete,
  setReportLogo,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isRecentResultsChecked,
  setIsRecentResultsChecked,
  generalNotes,
  setGeneralNotes,
}) => {
  if (!isFetching && accountInfo) {
    return (
      <>
        <div className={styles.centeredWrapper}>
          1. Selected Athlete:
          <span
            className={styles.athlete}
          >{` ${athlete.firstName} ${athlete.middleName} ${athlete.lastName}`}</span>
        </div>
        <div className={styles.centeredWrapper}>
          2. Upload your logo or keep existing one
        </div>
        <div>
          <FilePond
            files={reportLogo}
            allowMultiple={false}
            name="logo"
            onupdatefiles={(fileItems) => {
              setReportLogo(fileItems.map((fileItem) => fileItem.file));
            }}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
        </div>
        <div className={styles.centeredWrapper}>
          3. Pick either date range or most recent results
        </div>
        <div className={styles.datePickerContainer}>
          <div>
            <DatePicker
              // className={styles.datePicker}
              placeholderText={"Enter Start Date"}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div>
            <DatePicker
              // className={styles.datePicker}
              placeholderText={"Enter End Date"}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </div>
        <div className={styles.centeredWrapper}>
          <label>
          <input
            type="checkbox"
            checked={isRecentResultsChecked}
            onChange={(e) => {
              setIsRecentResultsChecked(!isRecentResultsChecked);
            }}
          />
          Take the most recent results
          </label>
        </div>
        <div className={styles.centeredWrapper}>
          4. Enter any general notes for the report
        </div>
        <Field>
          <Label>General Notes</Label>
          <TextArea
            value={generalNotes}
            onChange={(e) => setGeneralNotes(e.target.value)}
          />
        </Field>
      </>
    );
  }
  return null;
};

export default AddEpicIndexReport;
