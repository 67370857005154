import React, { useContext } from "react";
import { ModalContext } from "../../../../../contexts/ModalContext";
import Event from "./Event";
import {
  calculateFields,
  calculateInitialValues,
  calculateValidations,
  isValidMuliSplit,
} from "./multiSplitUtils";

const Flying = ({ athleteId, isMetric, numSplits, isDistanceAssociated }) => {

  const { replaceModal } = useContext(ModalContext);

  return (
    <Event
      title={"Add Flying"}
      athleteId={athleteId}
      isMetric={isMetric}
      fields={calculateFields(numSplits, isDistanceAssociated)}
      initialValues={calculateInitialValues(numSplits, isDistanceAssociated)}
      validations={calculateValidations(numSplits, isDistanceAssociated)}
      isValidEvent={(times) =>
        isValidMuliSplit(numSplits, isDistanceAssociated, times, "Flying")
      }
      onBack={() =>
        replaceModal("SelectSplits", { event: "Flying", athleteId, isMetric })
      }
    />
  );
};

export default Flying;
