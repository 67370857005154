import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/button/Button";
import ProgressMessage from "../../components/progressmessage/ProgressMessage";
import TextField from "../../containers/textfield/TextField";
import styles from "./Login.module.scss";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import validateLogin from "./loginValidation";
import { getUserService } from "../../services/coachService";
import { getCompanyService } from "../../services/thirdPartyIntegrationService";
import { AccountContext } from "../../contexts/AccountContext";
import { AppContext } from "../../contexts/AppContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationMessages, setValidationMessages] = useState({
    email: null,
    password: null,
  });
  const [progressMessage, setProgressMessage] = useState(null);
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { setAccountInfo } = useContext(AccountContext)
  const { appDispatch} = useContext(AppContext);
  const history = useHistory();

  const handleLogin = async () => {
    try {
      const validationErrors = validateLogin(email, password);
      if (validationErrors) {
        const { email, password } = validationErrors;
        setValidationMessages({ email, password });
        return;
      }

      setProgressMessage({
        isLoading: true,
        message: "Logging In...",
        isError: false,
      });
      const result = await Auth.signIn(email, password);
      const { attributes } = result;
      const { sub: userId } = attributes;

      const accountSettings = await getUserService(userId);
      const companies = await getCompanyService();

      const { metricSettingEnabled, thirdPartySettings } = accountSettings;

      const thirdPartyIntegration = [];
      companies.forEach(company => {
        const { id, companyName } = company
        let isIntegrated = false
        const approvedCompany = thirdPartySettings.find(tps => tps.companyId === id)
        if (approvedCompany) {
          isIntegrated = true
        }
        thirdPartyIntegration.push( { id, name: companyName, isIntegrated })
      })

      accountSettings.thirdPartyIntegration = thirdPartyIntegration
      delete accountSettings.thirdPartySettings
      setAccountInfo(accountSettings);
      appDispatch({ type: 'SET_UNITS_SETTING', payload: metricSettingEnabled });      

      authDispatch({ type: "AUTH_USER", payload: userId });
      history.push("/");
    } catch (e) {
      const { code, message } = e;
      if (code === "UserNotConfirmedException") {
        authDispatch({ type: "CONFIRM_USER", payload: email });
        history.push("/confirm");
      } else {
        setProgressMessage({ isLoading: false, message, isError: true });
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }



  return (
    <>
      {progressMessageComponent}
      <TextField
        label={"Email"}
        value={email}
        onChange={(e) => {
          e.preventDefault();
          setEmail(e.target.value);
        }}
        helperText={validationMessages.email}
      />
      <TextField
        label={"Password"}
        inputType={"password"}
        value={password}
        onChange={(e) => {
          e.preventDefault();
          setPassword(e.target.value);
        }}
        helperText={validationMessages.password}
      />
      <div className={styles.forgotPasswordContainer}>
        <Link to="/forgotpassword">Forgot your password?</Link>
      </div>
      <Button onClick={handleLogin} fullWidth>
        LOGIN
      </Button>
    </>
  );
};

export default Login;
