import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import Button from "../../../../components/button/Button";
import { ModalContext } from "../../../../contexts/ModalContext";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { AuthContext } from "../../../../contexts/AuthContext";
import {
  getPaginatedAthletes,
  uploadAthletesService,
} from "../../../../services/athleteService";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import { FilePond } from "react-filepond";
import styles from "./UploadAthletes.module.scss";
import "filepond/dist/filepond.min.css";

const UploadAthletes = () => {
  
  const { hideModal } = useContext(ModalContext);
  const [progressMessage, setProgressMessage] = useState(null);
  const { state: authState } = useContext(AuthContext);
  const { athletesState, athletesDispatch } = useContext(AthletesContext);
  const [uploadedAthletes, setUploadedAthletes] = useState([]);
  const { activeRoster } = athletesState;

  const handleUploadAthletes = async () => {
    setProgressMessage({
      isLoading: true,
      message: "Uploading Athletes...",
      isError: false,
    });
    if (uploadedAthletes.length === 0) {
      setProgressMessage({
        isLoading: false,
        message: "You must upload a file",
        isError: true,
      });
    }
    try {
      const { userId } = authState;

      let athleteSpreadsheet = new FormData();
      athleteSpreadsheet.set("File", uploadedAthletes[0]);

      if (activeRoster === -1) {
        await uploadAthletesService(userId, null, athleteSpreadsheet);
      } else {
        await uploadAthletesService(userId, activeRoster, athleteSpreadsheet);
      }
      const { currentPage, athletesPerPage, searchQuery, sort } = athletesState;
      const { athletes, totalAthletes } = await getPaginatedAthletes(
        userId,
        currentPage,
        athletesPerPage,
        activeRoster,
        searchQuery,
        sort
      );
      athletesDispatch({
        type: "FETCH_ATHLETES",
        payload: { athletes, totalAthletes },
      });
      setProgressMessage({
        isLoading: false,
        message: "Successfully uploaded athletes.",
        isError: false,
      });
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed adding roster, please try again",
            isError: true,
          });
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  const modalTitle =
    activeRoster === -1 ? "Upload Athletes" : "Upload Athletes To Roster";

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>{modalTitle}</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.uploadMessage}>
            Note: The first, middle, and last names fields are required. The
            rest are optional and can be left blank. Examples will be included
            with the template.
          </div>
          <FilePond
            files={uploadedAthletes}
            onupdatefiles={(fileItems) =>
              setUploadedAthletes(fileItems.map((fileItem) => fileItem.file))
            }
          />
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button
              href={
                "https://dashr-roster-excel-files.s3.amazonaws.com/rostertemplate.xlsx"
              }
              type="back"
            >
              Download Template
            </Button>
            <Button onClick={handleUploadAthletes}>Upload Athletes</Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default UploadAthletes;
