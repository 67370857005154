import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import { ModalContext } from "../../../../../contexts/ModalContext";
import styles from "./Event.module.scss";
import { useForm } from "../../../../../hooks/useForm";
import Input from "../../../../../components/form/input/Input";
import InputHelperText from "../../../../../components/form/inputhelpertext/InputHelperText";
import ProgressMessage from "../../../../../components/progressmessage/ProgressMessage";
import Button from "../../../../../components/button/Button";
import { AuthContext } from "../../../../../contexts/AuthContext";
import resultService, {
  getPaginatedEvents,
} from "../../../../../services/resultsService";
import Select from "../../../../../components/select/Select";
import { ResultsContext } from "../../../../../contexts/ResultsContext";
import moment from "moment";

const Event = ({
  title,
  athleteId,
  isMetric,
  fields,
  onBack,
  validations,
  isValidEvent,
  initialValues,
  children,
}) => {
  const { replaceModal, hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { resultsState, resultsDispatch } = useContext(ResultsContext);
  const [progressMessage, setProgressMessage] = useState(null);
  const [isAddResultButtonDisabled, setIsAddResultButtonDisabled] =
    useState(false);
  const [showExitButton, setShowExitButton] = useState(false);
  const {
    values,
    errors,
    bindField,
    isValid: areFieldsValid,
  } = useForm({
    validations: validations,
    initialValues: initialValues,
  });

  const handleCancelButtonPress = () => {
    if (showExitButton) {
      hideModal();
    } else {
      if (onBack) {
        onBack();
      } else {
        replaceModal("SelectEvent", { athleteId });
      }
    }
  };

  const handleSaveEvent = async () => {
    try {
      if (areFieldsValid()) {
        setIsAddResultButtonDisabled(true);
        setProgressMessage({
          isLoading: true,
          message: "Adding Result...",
          isError: false,
        });
        const eventValidation = isValidEvent(values);
        const { isValid, message, eventFields } = eventValidation;
        if (!isValid) {
          setIsAddResultButtonDisabled(false);
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
          return;
        }
        const event = {
          athleteID: athleteId,
          isMetric,
          ...eventFields,
        };
        const {
          currentPage,
          resultsPerPage,
          searchQuery,
          startDateFilter,
          endDateFilter,
          activeRosterFilter,
          sort,
        } = resultsState;
        await resultService.addResult(authState.userId, event);
        const fetchedPaginatedResults = await getPaginatedEvents(
          authState.userId,
          currentPage,
          resultsPerPage,
          activeRosterFilter,
          searchQuery,
          sort,
          startDateFilter,
          endDateFilter
        );
        const { results, totalResults } = fetchedPaginatedResults;
        resultsDispatch({
          type: "SET_RESULTS",
          payload: {
            results,
            totalResults,
          },
        });
        setProgressMessage({
          isLoading: false,
          message: "Successfully Added Result!",
          isError: false,
        });
        setShowExitButton(true);
      }
    } catch (err) {
      setIsAddResultButtonDisabled(false);
      setProgressMessage({
        isLoading: false,
        message: "Failed Adding Result, Please Try Again.",
        isError: true,
      });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>{title}</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div className={styles.container}>
            {fields.map((inputField) => {
              const { type } = inputField;
              const { label, property, options } = inputField;
              const { value, onChange } = bindField(property);
              if (type === "input") {
                return (
                  <div className={styles.fieldContainer}>
                    <div className={styles.centeredWrapper}>{label}</div>
                    <div>
                      <Input
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        type={"text"}
                        centeredText
                      />
                      <InputHelperText helperText={errors[property]} />
                    </div>
                  </div>
                );
              } else if (type === "select") {
                return (
                  <div className={styles.fieldContainer}>
                    <div className={styles.centeredWrapper}>{label}</div>
                    <div>
                      <Select
                        options={options}
                        value={value}
                        onOptionsChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                );
              }
            })}
            {children}
          </div>
        </ModalCardBody>
        <ModalCardFooter className={styles.modalFooter}>
          {progressMessageComponent}
          <div className={styles.buttonWrapper}>
            <Button onClick={handleCancelButtonPress} type={"back"}>
              {showExitButton ? "Exit" : "Back"}
            </Button>
            <Button
              onClick={handleSaveEvent}
              disabled={isAddResultButtonDisabled}
            >
              Add Result
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default Event;
