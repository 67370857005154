const getAthleteFields = (isMetric) => {
  return [
    { label: "First Name", property: "firstName" },
    { label: "Middle Name", property: "middleName" },
    { label: "Last Name", property: "lastName" },
    { label: "Badge ID", property: "badgeID" },
    { label: "Third Party ID", property: "thirdPartyID" },
    { label: "Sport", property: "sport" },
    { label: "Position", property: "position" },
    { label: `Height (${isMetric ? "CM" : "IN"})`, property: "height" },
    { label: `Weight (${isMetric ? "KG" : "LBS"})`, property: "weight" },
    { label: `Reach (${isMetric ? "CM" : "IN"})`, property: "reach" },
    { label: `Hand Size (${isMetric ? "CM" : "IN"})`, property: "handSize" },
    { label: `Wingspan (${isMetric ? "CM" : "IN"})`, property: "wingSpan" },
    { label: "Shoe Size", property: "shoeSize" },
    { label: "Zip Code", property: "zipCode" },
    { label: "Graduation Year", property: "graduationYear" },
    { label: "Birthday (mm/dd/yyyy)", property: "birthDate" },
    { label: "Sex", property: "sex" },
    { label: "Custom Field 1", property: "customField1" },
    { label: "Custom Field 2", property: "customField2" },
    { label: "Custom Field 3", property: "customField3" },
    { label: "Custom Field 4", property: "customField4" },
  ];
};

const formatAthleteForBackendService = (athleteFields) => {
  return {
    firstName: athleteFields.firstName,
    middleName: athleteFields.middleName,
    lastName: athleteFields.lastName,
    badgeID: athleteFields.badgeID,
    thirdPartyID: athleteFields.thirdPartyID,
    sport: athleteFields.sport,
    position: athleteFields.position,
    height: athleteFields.height ? parseFloat(athleteFields.height) : null,
    weight: athleteFields.weight ? parseFloat(athleteFields.weight) : null,
    reach: athleteFields.reach ? parseFloat(athleteFields.reach) : null,
    handSize: athleteFields.handSize
      ? parseFloat(athleteFields.handSize)
      : null,
    wingSpan: athleteFields.wingSpan
      ? parseFloat(athleteFields.wingSpan)
      : null,
    shoeSize: athleteFields.shoeSize
      ? parseFloat(athleteFields.shoeSize)
      : null,
    zipCode: athleteFields.zipCode,
    graduationYear: athleteFields.graduationYear,
    birthDate: athleteFields.birthDate ? athleteFields.birthDate : null,
    sex: athleteFields.sex,
    customField1: athleteFields.customField1,
    customField2: athleteFields.customField2,
    customField3: athleteFields.customField3,
    customField4: athleteFields.customField4,
  };
};

export { getAthleteFields, formatAthleteForBackendService };
