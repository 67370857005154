import axios from "axios";
import environment from "../environment/environment";

const { API_URL } = environment;

export const getUserService = async (userId) => {
  const { data: coachInfo } = await axios.get(`${API_URL}/coach/${userId}`);
  return coachInfo;
};

export const updateUserProfileService = async (userId, updatedInfo) => {
  const { data: coachInfo } = await axios.put(
    `${API_URL}/coach/${userId}`,
    updatedInfo
  );
  return coachInfo;
};

export const setMetricSettingService = async function (userId, metricSetting) {
  return await axios.post(`${API_URL}/coach/${userId}/settings/metricsetting`, {
    metricSetting,
  });
};

export const createUser = async function (userId, email) {
  const { data: newUser } = await axios.post(`${API_URL}/coach/`, {
    email,
    coachID: userId,
  });
  return newUser;
};

export const deleteUserData = async (userId) => {
  await axios.delete(`${API_URL}/coach/${userId}/harddelete`)
}
