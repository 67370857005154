import { Auth } from "aws-amplify";
import {
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarItem,
  NavbarLink,
  NavbarMenu,
  NavbarDropdown,
  NavbarDivider,
} from "bloomer";
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./TopNavbar.module.scss";

const TopNavbar = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const history = useHistory();
  const { currentPageTitle } = appState;

  const menu = [
    { path: "/dashboard/home", label: "Dashboard" },
    { path: "/dashboard/athletes", label: "Athletes" },
    { path: "/dashboard/reports", label: "Reports" },
    { path: "/dashboard/results", label: "Results" },
    { path: "/dashboard/account", label: "Account" },
  ];

  const logout = async () => {
    await Auth.signOut();
    authDispatch({ type: "UNAUTH_USER" });
    history.push("/");
  }

  return (
    <Navbar className={styles.navBar}>
      <NavbarBrand>
        <NavbarItem>{currentPageTitle ? currentPageTitle : ""}</NavbarItem>
        <NavbarBurger
          className={styles.hamburgerMenu}
          isActive={isMobileMenuActive}
          onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
        />
      </NavbarBrand>
      <NavbarMenu
        className={styles.navbarMenu}
        isActive={isMobileMenuActive}
        onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
        isHidden="desktop"
      >
        {menu.map((menuItem) => {
          const { path, label } = menuItem;
          return (
            <Link key={label} className={styles.navbarMenuLink} to={path}>
              {label}
            </Link>
          );
        })}
        <NavbarItem className={styles.navbarMenuLogout} onClick={logout}>Logout</NavbarItem>
      </NavbarMenu>
      <NavbarEnd>
        <NavbarItem hasDropdown isHoverable isHidden="touch">
          <NavbarLink>
            <i className="material-icons">account_circle</i>
          </NavbarLink>
          <NavbarDropdown>
            <Link to="/dashboard/account" className="navbar-item">
              Settings
            </Link>
            <NavbarDivider />
            <NavbarItem
              className={styles.logoutDropdown}
              onClick={logout}
            >
              Logout
            </NavbarItem>
          </NavbarDropdown>
        </NavbarItem>
      </NavbarEnd>
    </Navbar>
  );
};

export default TopNavbar;
