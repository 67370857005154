import _ from "lodash";
import React, { useState, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import Button from "../../components/button/Button";
import FetchMessage from "../../components/fetchMessage/FetchMessage";
import SearchBar from "../../components/searchbar/SearchBar";
import { AppContext } from "../../contexts/AppContext";
import { AuthContext } from "../../contexts/AuthContext";
import { ModalContext } from "../../contexts/ModalContext";
import { ResultsContext } from "../../contexts/ResultsContext";
import { getPaginatedEvents } from "../../services/resultsService";
import styles from "./Results.module.scss";
import "../../styles/datepicker.scss";
import Select from "../../components/select/Select";
import moment from "moment";
import { AthletesContext } from "../../contexts/AthletesContext";
import { getRosters } from "../../services/rosterService";
import PaginatedTable from "../../containers/paginatedtable/PaginatedTable";

const tableColumns = [
  { path: "formattedDate", label: "Date" },
  { path: "firstName", label: "First Name" },
  { path: "middleName", label: "Middle Name" },
  { path: "lastName", label: "Last Name" },
  { path: "event", label: "Event" },
  { path: "result", label: "Result" },
];

const Results = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeRoster, setActiveRoster] = useState(-1);
  const { state: authState } = useContext(AuthContext);
  const { resultsState, resultsDispatch } = useContext(ResultsContext);
  const { athletesState, athletesDispatch } = useContext(AthletesContext);
  const { showModal } = useContext(ModalContext);
  const { appDispatch } = useContext(AppContext);
  const { rosters } = athletesState;
  const { userId } = authState;
  const {
    currentPage,
    resultsPerPage,
    searchQuery,
    totalResults,
    results,
    sortColumn,
    startDateFilter,
    endDateFilter,
    activeRosterFilter,
    sort,
  } = resultsState;

  useEffect(() => {
    appDispatch({ type: "SET_CURRENT_PAGE_TITLE", payload: "Results" });
  }, [appDispatch]);

  useEffect(() => {
    if (!rosters) {
      const fetchRosters = async () => {
        const fetchedRosters = await getRosters(userId);
        athletesDispatch({ type: "FETCH_ROSTERS", payload: fetchedRosters });
      };
      fetchRosters();
    }
  }, [athletesDispatch, userId, rosters]);

  useEffect(() => {
    const fetchResults = async () => {
      const fetchedPaginatedResults = await getPaginatedEvents(
        userId,
        currentPage,
        resultsPerPage,
        activeRosterFilter,
        searchQuery,
        sort,
        startDateFilter,
        endDateFilter
      );
      const { results, totalResults } = fetchedPaginatedResults;
      resultsDispatch({
        type: "SET_RESULTS",
        payload: { results, totalResults },
      });
    };
    fetchResults();
  }, [
    resultsDispatch,
    currentPage,
    userId,
    resultsPerPage,
    searchQuery,
    sort,
    activeRosterFilter,
    startDateFilter,
    endDateFilter,
  ]);

  const filterResults = async () => {
    const startDateUtc = moment(startDate).startOf("day").toISOString();
    const endDateUtc = moment(endDate).endOf("day").toISOString();
    resultsDispatch({
      type: "SET_FILTER",
      payload: {
        startDateFilter: startDateUtc,
        endDateFilter: endDateUtc,
        activeRosterFilter: activeRoster,
      },
    });
  };

  const handleResultsPerPage = (currentPage, resultsPerPage) => {
    resultsDispatch({
      type: "CHANGE_RESULTS_PER_PAGE",
      payload: resultsPerPage,
    });
  };

  const handlePageChange = (page) => {
    resultsDispatch({ type: "CHANGE_CURRENT_PAGE", payload: page });
  };

  if (!results || !rosters) {
    return <FetchMessage message={"Fetching Results"} />;
  }

  return (
    <>
      <div className={styles.actionContainer}>
        <SearchBar
          query={searchQuery}
          onChange={(query) =>
            resultsDispatch({ type: "SET_SEARCH_QUERY", payload: query })
          }
          onSearch={(query) =>
            resultsDispatch({ type: "SET_SEARCH_QUERY", payload: query })
          }
        />
        {/* <Button
          className={styles.exportButton}
          onClick={() => showModal("UploadResult")}
        >
          Upload Results
        </Button> */}
        <Button
          className={styles.exportButton}
          onClick={() => showModal("SelectAthlete")}
        >
          Add Result
        </Button>
        <Button onClick={() => showModal("ExportResult", null)}>
          Export Results
        </Button>
      </div>
      <div className={styles.datePickerContainer}>
        <div className={styles.datePicker}>
          <DatePicker
            placeholderText={"Enter Start Date"}
            selected={startDate}
            onChange={setStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDateFilter}
            isClearable
          />
        </div>
        <div className={styles.datePicker}>
          <DatePicker
            placeholderText={"Enter End Date"}
            selected={endDate}
            onChange={setEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            isClearable
          />
        </div>
        <Select
          options={rosters}
          value={activeRoster}
          onOptionsChange={setActiveRoster}
        />
        <Button className={styles.filterButton} onClick={filterResults}>
          Filter
        </Button>
      </div>
      <PaginatedTable
        columns={tableColumns}
        data={results}
        sortColumn={sortColumn}
        onSort={(sortColumn) => {
          console.log(sortColumn)
          resultsDispatch({
            type: "SET_SORT_COLUMN",
            payload: sortColumn,
          });
        }}
        total={totalResults}
        pageSize={resultsPerPage}
        onRowClick={(result) => showModal("ResultDetail", { result })}
        onShowSizeChange={handleResultsPerPage}
        onPageChange={handlePageChange}
        onGotoChange={handlePageChange}
      />
    </>
  );
};

export default Results;
