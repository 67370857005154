import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import { ModalContext } from "../../../contexts/ModalContext";
import styles from "./DeleteDataModal.module.scss";
import Button from "../../../components/button/Button";
import ProgressMessage from "../../../components/progressmessage/ProgressMessage";
import { AuthContext } from "../../../contexts/AuthContext";
import { deleteUserData } from "../../../services/coachService";
import { AthletesContext } from "../../../contexts/AthletesContext";
import { ReportsContext } from "../../../contexts/ReportsContext";
import { ResultsContext } from "../../../contexts/ResultsContext";

const DeleteDataModal = () => {
  const [progressMessage, setProgressMessage] = useState(null);
  const { hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch } = useContext(AthletesContext);
  const { resultsDispatch } = useContext(ResultsContext);
  const { reportsDispatch } = useContext(ReportsContext);
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [backButtonLabel, setBackButtonLabel] = useState("Cancel");

  const deleteData = async () => {
    try {
      setProgressMessage({
        isLoading: true,
        message: "Deleting your data",
        isError: false,
      });
      const { userId } = authState;
      await deleteUserData(userId);
      setProgressMessage({
        isLoading: false,
        message: "Sucessfully deleted your data",
        isError: false,
      });
      setDeleteButtonDisabled(true);
      setBackButtonLabel("Exit");
      athletesDispatch({ type: "INITIAL_STATE" });
      resultsDispatch({ type: "INITIAL_STATE" });
      reportsDispatch({ type: "INITIAL_STATE" });
    } catch (err) {
      setProgressMessage({
        isLoading: false,
        message: "Something went wrong, please try again",
        isError: false,
      });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Delete Data</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          Are you sure you want to delete all of your data?
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              {backButtonLabel}
            </Button>
            <Button
              type={"delete"}
              onClick={deleteData}
              disabled={isDeleteButtonDisabled}
            >
              Delete
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default DeleteDataModal;
