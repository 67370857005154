import axios from "axios";
import environment from "../environment/environment";
import moment from "moment";

const { API_URL } = environment;

const formatAthlete = (athleteFromServer) => {
  const formattedAthlete = {};
  for (const property in athleteFromServer) {
    if (property === "birthDate") {
      formattedAthlete[property] = athleteFromServer.birthDate
        ? moment(athleteFromServer.birthDate).format("MM/DD/YYYY")
        : "";
    } else {
      if (!athleteFromServer[property]) {
        formattedAthlete[property] = athleteFromServer[property] = "";
      } else {
        formattedAthlete[property] = athleteFromServer[property];
      }
    }
  }
  return formattedAthlete;
};


export const getRosters = async (userId) => {
  const { data: rosters } = await axios.get(`${API_URL}/roster/${userId}`);
  const allRosters = [{ id: -1, name: "All Athletes" }, ...rosters];
  return allRosters;
};

export const addRoster = async (userId, roster) => {
  const { data: addedRoster } = await axios.post(
    `${API_URL}/roster/${userId}`,
    roster
  );
  return addedRoster;
};

export const deleteRoster = async (userId, rosterId) => {
  const { data: removedRoster } = await axios.delete(
    `${API_URL}/roster/${userId}/${rosterId}`
  );
  return removedRoster;
};

export const addRosterAthleteService = async (userId, rosterId, athleteId) => {
  const { data: addedAthlete } = await axios.post(
    `${API_URL}/rosterathlete/${userId}/${rosterId}/${athleteId}`,
    {}
  );
  return formatAthlete(addedAthlete);;
};

export const addNewAthleteToRoster = async (userId, rosterId, athlete) => {
  const { data: addedAthlete } = await axios.post(
    `${API_URL}/rosterathlete/${userId}/${rosterId}`,
    athlete
  );
  return addedAthlete;
};

export const deleteRosterAthleteService = async (
  coachId,
  rosterId,
  athleteId
) => {
  const { data } = await axios.delete(
    `${API_URL}/rosterathlete/${coachId}/${rosterId}/${athleteId}`
  );
  return data;
};

export const uploadRosterAthletesService = async (
  userId,
  rosterId,
  athletes
) => {
  const { data: returnedAthletes } = await axios.post(
    `${API_URL}/rosterathlete/upload/${userId}/${rosterId}`,
    athletes,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return returnedAthletes;
};
