// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordLengthRegex = /^(?=.{8,})/;
const passwordCapitalRegex = /^(?=.*[A-Z])/;
const passwordNumericRegex = /^(?=.*[0-9])/;

const validateEmail = (email) => {
  if (email.trim() === "") {
    return "Email is required";
  }
  if (!emailRegex.test(email.trim())) {
    return "Email is not valid";
  }
  return null;
};

const validatePassword = (password) => {
  if (password === "") {
    return "Password is required";
  } else if (!passwordLengthRegex.test(password)) {
    return "Password must be atleast 8 characters long.";
  } else if (!passwordCapitalRegex.test(password)) {
    return "Password must contain atleast 1 uppercase letter.";
  } else if (!passwordNumericRegex.test(password)) {
    return "Password must contain atleast 1 numeric character.";
  }
  return null;
};

const validateConfirmPassword = (password, confirmPassword) => {
  if (confirmPassword) {
    if (confirmPassword === "") {
      return "You must confirm your password";
    }
    if (confirmPassword !== password) {
      return "Your passwords must match";
    }
  }
  return null;
};

const validateSignup = (email, password, confirmPassword) => {
  const emailValidation = validateEmail(email);
  const passwordValidation = validatePassword(password);
  const confirmPasswordValidation = validateConfirmPassword(
    password,
    confirmPassword
  );
  
  if (!emailValidation && !passwordValidation && !confirmPasswordValidation) {
    return null;
  }

  return {
    email: emailValidation,
    password: passwordValidation,
    confirmPassword: confirmPasswordValidation,
  };
};

export default validateSignup;
