import React from "react";
import MenuItem from "./menuitem/MenuItem";
import styles from "./SideNavbar.module.scss";
import logo from "../../assets/images/logo.png";

const SideNavbar = () => {
  const menu = [
    { icon: "assignment", label: "Athletes", path: "/dashboard/athletes" },
    { icon: "description", label: "Reports", path: "/dashboard/reports" },
    { icon: "content_paste", label: "Results", path: "/dashboard/results" },
  ];

  return (
    <div className={styles.sideNavbarContainer}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.menuContainer}>
        {menu.map((menuItem) => {
          const { label, icon, path } = menuItem;
          return <MenuItem key={label} label={label} icon={icon} path={path} />;
        })}
      </div>
    </div>
  );
};

export default SideNavbar;
