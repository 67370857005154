import React from "react";
import TableBody from "./tablebody/TableBody";
import TableHead from "./tablehead/TableHead";

import styles from "./Table.module.scss";

const Table = ({ columns, sortColumn, onSort, data, onRowClick }) => {
  return (
    <table className={styles.table}>
      <TableHead columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} onRowClick={onRowClick} />
    </table>
  );
};

export default Table;
