import axios from "axios";
import environment from "../environment/environment";

const { API_URL } = environment;

const formatResults = (results) => {
  results.forEach((result) => {
    result.formattedDate = new Date(result.date).toDateString();
    const athlete = result.athlete;
    result.firstName = athlete.firstName;
    result.middleName = athlete.middleName;
    result.lastName = athlete.lastName;
    if (result.event === "Dash" || result.event === "Flying") {
      const { splitTime: splitTimes, finalTime } = result;
      if (result.startDistance) {
        result.startDistance = result.startDistance.toFixed(0);
      }
      splitTimes.forEach((splitTime) => {
        if (splitTime.distance) {
          splitTime.distance = splitTime.distance.toFixed(0);
        }
        splitTime.time = splitTime.time.toFixed(2);
      });
      if (finalTime.distance) {
        finalTime.distance = finalTime.distance.toFixed(2);
      }
      finalTime.time = finalTime.time.toFixed(2);
      result.result = result.finalTime.time;
    } else if (result.event === "ProAgility" || result.event === "LDrill") {
      if (result.splitTime) {
        result.splitTime = result.splitTime.toFixed(2);
      }
      result.finalTime = result.finalTime.toFixed(2);
      result.result = result.finalTime;
    } else if (
      result.event === "Vertical" ||
      result.event === "BroadJump" ||
      result.event === "ApproachVertical"
    ) {
      result.jumpDistance = result.jumpDistance.toFixed(2);
      result.result = result.jumpDistance;
    } else if (
      result.event === "ReactAgility" ||
      result.event === "ReactShuttle"
    ) {
      if (result.reactionTime)
        result.reactionTime = result.reactionTime.toFixed(2);
      result.splitTime = result.splitTime.toFixed(2);
      result.finalTime = result.finalTime.toFixed(2);
      result.result = result.finalTime;
    } else if (result.event === "Lap") {
      result.time1 = result.time1.toFixed(2);
      result.time2 = result.time2.toFixed(2);
      result.time3 = result.time3.toFixed(2);
      result.time4 = result.time4.toFixed(2);
      result.result = result.time1
    } else if (result.event === "Lifting") {
      result.weight = result.weight.toFixed(0);
      result.reps = result.reps.toFixed(0)
      result.result = result.weight
    } else if (result.event === "505AgilityTest") {
      result.finalTime = result.finalTime.toFixed(2)
      result.result = result.finalTime
    } else if (result.event === "RAST") {
      result.finalTime = result.finalTime.toFixed(2)
      result.result = result.finalTime
    } else if (result.event === "TTest") {
      result.finalTime = result.finalTime.toFixed(2)
      result.result = result.finalTime
    }
  });
  return results;
};

export const getPaginatedEvents = async (
  userId,
  pageNumber,
  resultsPerPage,
  rosterId,
  searchQuery,
  sort,
  startDate,
  endDate
) => {
  let apiUrl = `${API_URL}/event/${userId}/paginated?pageNumber=${pageNumber}&pageSize=${resultsPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`;
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`;
  }
  if (startDate) {
    apiUrl = apiUrl + `&startDate=${startDate}`;
  }
  if (endDate) {
    apiUrl = apiUrl + `&endDate=${endDate}`;
  }
  const { data: paginatedResultData } = await axios.get(apiUrl);

  const { data: results, totalRecords: totalResults } = paginatedResultData;

  return { results: formatResults(results), totalResults };
};

export const getExportedEvents = async (
  userId,
  pageNumber,
  resultsPerPage,
  rosterId,
  searchQuery,
  sort,
  startDate,
  endDate
) => {
  let apiUrl = `${API_URL}/event/${userId}/export?pageNumber=${pageNumber}&pageSize=${resultsPerPage}`;
  if (rosterId) {
    apiUrl = apiUrl + `&rosterId=${rosterId}`;
  }
  if (searchQuery) {
    apiUrl = apiUrl + `&searchQuery=${searchQuery}`;
  }
  if (sort) {
    apiUrl = apiUrl + `&sort=${sort}`;
  }
  if (startDate) {
    apiUrl = apiUrl + `&startDate=${startDate}`;
  }
  if (endDate) {
    apiUrl = apiUrl + `&endDate=${endDate}`;
  }
  const { data: results } = await axios.get(apiUrl);
  return formatResults(results);
};

export const deleteResultService = async (userId, resultId, callback) => {
  const { data: response } = await axios.delete(
    `${API_URL}/event/${userId}/${resultId}`
  );
  return response;
};

const addResult = async (userId, result) => {
  const { data: addedResult } = await axios.post(
    `${API_URL}/event/${userId}`,
    result
  );
  return addedResult;
};

export const uploadResultsService = async (userId, excelFile) => {
  let apiUrl = `${API_URL}/event/${userId}/upload`;

  const response = await axios.post(apiUrl, excelFile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

const resultService = {
  addResult,
};

export default resultService;
