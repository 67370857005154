// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email) => {
  if (email === "") {
    return "Email is required";
  }
  if (!emailRegex.test(email)) {
    return "Email is not valid";
  }
  return null;
};

const validatePassword = (password) => {
  if (password === "") {
    return "Password is required";
  }
  return null;
};

const validateLogin = (email, password) => {
  const emailValidation = validateEmail(email);
  const passwordValidation = validatePassword(password);
  if (!emailValidation && !passwordValidation) {
    return null;
  }
  return { email: emailValidation, password: passwordValidation };
};

export default validateLogin;
