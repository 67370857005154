import React, { useEffect, useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  Select,
} from "bloomer";
import _ from "lodash";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./AddRosterAthlete.module.scss";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { ModalCardFooter } from "bloomer/lib/components/Modal/Card/ModalCardFooter";
import Button from "../../../../components/button/Button";
import { addRosterAthleteService } from "../../../../services/rosterService";
import { AuthContext } from "../../../../contexts/AuthContext";
import { AthletesContext } from "../../../../contexts/AthletesContext";
import {
  getAthletes,
  getPaginatedAthletes,
} from "../../../../services/athleteService";
import FetchMessage from "../../../../components/fetchMessage/FetchMessage";
import SearchBar from "../../../../components/searchbar/SearchBar";
import Table from "../../../../components/table/Table";
import PaginatedTable from "../../../paginatedtable/PaginatedTable";
import { determineAthleteSortOrder } from "../../../../utilities/sortOrder";

const tableColumns = [
  { path: "firstName", label: "First Name" },
  { path: "middleName", label: "Middle Name" },
  { path: "lastName", label: "Last Name" },
];

const AddRosterAthlete = ({ roster }) => {
  const [progressMessage, setProgressMessage] = useState(null);
  const { showModal, hideModal, replaceModal } = useContext(ModalContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [finalSearchQuery, setFinalSearchQuery] = useState("");
  const [totalAthletes, setTotalAthletes] = useState(0);
  const [athletesPerPage, setAthletesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch } = useContext(AthletesContext);
  const [athletes, setAthletes] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [sortColumn, setSortColumn] = useState({
    path: "lastName",
    order: "asc",
  });

  useEffect(() => {
    const fetchAthletes = async () => {
      try {
        const { userId } = authState;
        const sort = determineAthleteSortOrder(sortColumn);
        const {
          athletes: fetchedAthletes,
          totalAthletes: fetchedTotalAthletes,
        } = await getPaginatedAthletes(
          userId,
          currentPage,
          athletesPerPage,
          null,
          finalSearchQuery,
          sort
        );

        setAthletes(fetchedAthletes);
        setTotalAthletes(fetchedTotalAthletes);

        setIsFetching(false);
      } catch {}
    };
    fetchAthletes();
  }, [authState, currentPage, athletesPerPage, finalSearchQuery, sortColumn]);

  let modalContents = null;

  if (isFetching && !athletes) {
    modalContents = <FetchMessage message={"Fetching Athletes"} />;
  } else {
    modalContents = (
      <>
        <div className={styles.centeredWrapper}>
          If you choose to add a new athlete, you will take up 1 roster spot
        </div>
        <div className={styles.centeredWrapper}>
          <div>Select your athlete</div>
          <SearchBar
            query={searchQuery}
            onChange={setSearchQuery}
            onSearch={(query) => setFinalSearchQuery(query)}
          />
          <PaginatedTable
            columns={tableColumns}
            data={athletes}
            sortColumn={sortColumn}
            onSort={(sortColumn) => setSortColumn(sortColumn)}
            total={totalAthletes}
            pageSize={athletesPerPage}
            onRowClick={(athlete) =>
              replaceModal("AddExistingAthlete", { athlete, roster })
            }
            onShowSizeChange={(currentPage, athletesPerPage) =>
              setAthletesPerPage(athletesPerPage)
            }
            onPageChange={(page) => setCurrentPage(page)}
            onGotoChange={(page) => setCurrentPage(page)}
          />
        </div>
      </>
    );
  }

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>{`Add Athlete to ${roster.name}`}</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          {modalContents}
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              Cancel
            </Button>
            <Button
              onClick={() => showModal("AddAthlete", { roster: roster.id })}
            >
              Add New Athlete
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default AddRosterAthlete;
