import React from "react";
import { Route, Switch } from "react-router-dom";
import Card from "../../components/card/Card";
import NewUserNavbar from "../../containers/newusernavbar/NewUserNavbar";
import Login from "../../pages/login/Login";
import Signup from "../../pages/signup/Signup";
import Confirm from "../../pages/confirm/Confirm";
import ForgotPassword from "../../pages/forgotpassword/ForgotPassword";
import ChangePassword from "../../pages/changepassword/ChangePassword";

import styles from "./Authentication.module.scss";

const Authentication = () => {
  return (
    <div className={styles.background}>
      <div className={styles.overlay}>
        <NewUserNavbar />
        <Card>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/confirm" exact component={Confirm} />
            <Route path="/forgotpassword" exact component={ForgotPassword} />
            <Route path="/changepassword" exact component={ChangePassword} />
          </Switch>
        </Card>
      </div>
    </div>
  );
};

export default Authentication;
