import React from "react";
import { decimalNumberPattern } from "../../../../../common/patterns/patterns";
import Event from "./Event";

const validations = {
  time1: {
    pattern: {
      value: decimalNumberPattern,
      message: "Time 1 needs to be a number",
    },
  },
  time2: {
    pattern: {
      value: decimalNumberPattern,
      message: "Time 2 needs to be a number",
    },
  },
  time3: {
    pattern: {
      value: decimalNumberPattern,
      message: "Time 3 needs to be a number",
    },
  },
  time4: {
    pattern: {
      value: decimalNumberPattern,
      message: "Time 4 needs to be a number",
    },
  },
};

const fields = [
  { type: "input", label: "Time 1", property: "time1" },
  { type: "input", label: "Time 2", property: "time2" },
  { type: "input", label: "Time 3", property: "time3" },
  { type: "input", label: "Time 4", property: "time4" },
];

const initialValues = {
  time1: "0.0",
  time2: "0.0",
  time3: "0.0",
  time4: "0.0",
}

const isValidLap = (values) => {
  const time1 = parseFloat(values.time1);
  const time2 = parseFloat(values.time2);
  const time3 = parseFloat(values.time3);
  const time4 = parseFloat(values.time4);

  const eventFields = {
    type: "Lap",
    time1,
    time2,
    time3,
    time4
  };
  return { isValid: true, message: null, eventFields };
};


const Lap = ({ athleteId, isMetric }) => {
  return (
    <Event
    title={"Add Lap"}
    athleteId={athleteId}
    isMetric={isMetric}
    fields={fields}
    initialInputValues={initialValues}
    validations={validations}
    isValidEvent={isValidLap}
  />
  );
};

export default Lap;
