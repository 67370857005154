import axios from "axios";
import environment from "../environment/environment";
const { API_URL } = environment;

export const getCompanyService = async () => {
  const { data: companies } = await axios.get(`${API_URL}/company`);
  return companies;
};

export const setThirdPartyIntegrationService = async (
  userId,
  companyId,
  enable
) => {
  if (enable) {
    await axios.post(`${API_URL}/coach/${userId}/settings/thirdparty`, {
      companyId,
    });
  } else {
    await axios.delete(`${API_URL}/coach/${userId}/settings/thirdparty/${companyId}`);
  }
};
