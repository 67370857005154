import { decimalNumberPattern } from "../../../../../common/patterns/patterns";

const decimalNumberValidation = (fieldName) => {
  return {
    pattern: {
      value: decimalNumberPattern,
      message: `${fieldName} needs to be a number`,
    },
  };
};

export const calculateValidations = (numSplits, isDistanceAssociated) => {
  const validations = {};

  for (let i = 0; i < numSplits; i++) {
    validations[`splitTime${i}`] = decimalNumberValidation(`Split time ${i}`);
    if (isDistanceAssociated) {
      validations[`distance${i}`] = decimalNumberValidation(`Distance ${i}`);
    }
  }
  validations["finalTime"] = decimalNumberValidation(`Final time`);
  if (isDistanceAssociated) {
    validations["finalDistance"] = decimalNumberValidation(`Final distance`);
  }

  return validations;
};

export const areDistancesIncreasing = (numSplits, times) => {
  let previousDistance = parseFloat(times.distance0);
  for (let i = 1; i < numSplits; i++) {
    const currentDistance = parseFloat(times[`distance${i}`]);
    if (currentDistance <= previousDistance) {
      return false;
    }
    previousDistance = currentDistance;
  }
  const finalDistance = parseFloat(times.finalDistance);
  if (finalDistance <= previousDistance) {
    return false;
  }
  return true;
};

export const areTimesIncreasing = (numSplits, times) => {
  let previousSplitTime = parseFloat(times.splitTime0);
  for (let i = 1; i < numSplits; i++) {
    const currentSplitTime = parseFloat(times[`splitTime${i}`]);
    if (currentSplitTime <= previousSplitTime) {
      return false;
    }
    previousSplitTime = currentSplitTime;
  }
  const finalTime = parseFloat(times.finalTime);
  if (finalTime <= previousSplitTime) {
    return false;
  }
  return true;
};

export const calculateFields = (numSplits, isDistanceAssociated) => {
  const fields = [];
  for (let i = 0; i < numSplits; i++) {
    fields.push({
      type: "input",
      label: `Split Time ${i + 1}`,
      property: `splitTime${i}`,
    });
    if (isDistanceAssociated) {
      fields.push({
        type: "input",
        label: `Distance ${i + 1}`,
        property: `distance${i}`,
      });
    }
  }
  fields.push({ type: "input", label: "Final Time", property: "finalTime" });
  if (isDistanceAssociated) {
    fields.push({
      type: "input",
      label: "Final Distance",
      property: "finalDistance",
    });
  }
  return fields;
};

export const calculateInitialValues = (numSplits, isDistanceAssociated) => {
  const initialValues = {};
  for (let i = 0; i < numSplits; i++) {
    initialValues[`splitTime${i}`] = "0.0";
    if (isDistanceAssociated) {
      initialValues[`distance${i}`] = "0.0";
    }
  }
  initialValues["finalTime"] = "0.0";
  if (isDistanceAssociated) {
    initialValues["finalDistance"] = "0.0";
  }
  return initialValues;
};

export const isValidMuliSplit = (
  numSplits,
  isDistanceAssociated,
  times,
  eventType
) => {
  const multiSplitFields = {};
  if (numSplits > 0) {
    if (!areTimesIncreasing(numSplits, times)) {
      return {
        isValid: false,
        message: "Times need to be increasing",
        eventFields: null,
      };
    }

    if (isDistanceAssociated) {
      if (!areDistancesIncreasing(numSplits, times)) {
        return {
          isValid: false,
          message: "Distances need to be increasing",
          eventFields: null,
        };
      }
    }

    const splitTimes = [];
    for (let i = 0; i < numSplits; i++) {
      const splitTime = { time: parseFloat(times[`splitTime${i}`]) };
      if (isDistanceAssociated) {
        splitTime.distance = parseFloat(times[`distance${i}`]);
      }
      splitTimes.push(splitTime);
    }
    multiSplitFields.splitTime = splitTimes;
  }
  const finalTime = { time: parseFloat(times.finalTime) };
  if (isDistanceAssociated) {
    finalTime.distance = parseFloat(times.finalDistance);
  }
  multiSplitFields.finalTime = finalTime;
  multiSplitFields.type = eventType
  
  return {
    isValid: true,
    message: null,
    eventFields: multiSplitFields,
  };
};
