import React, { useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./AddRoster.module.scss";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import TextField from "../../../textfield/TextField";
import { ModalCardFooter } from "bloomer/lib/components/Modal/Card/ModalCardFooter";
import Button from "../../../../components/button/Button";
import { addRoster } from "../../../../services/rosterService";
import { AuthContext } from "../../../../contexts/AuthContext";
import { AthletesContext } from "../../../../contexts/AthletesContext";

const AddRoster = () => {
  const [rosterName, setRosterName] = useState("");
  const [progressMessage, setProgressMessage] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);
  const { hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { athletesDispatch } = useContext(AthletesContext);
  const [isAddButtonDisabled, setAddButtonDisabled] = useState(false);
  const [backButtonLabel, setBackButtonLabel] = useState("Cancel");

  const handleAddRoster = async () => {
    if (rosterName === "") {
      setValidationMessage("Name is required");
    } else {
      setValidationMessage(null);
      setProgressMessage({
        isLoading: true,
        message: "Adding Roster...",
        isError: false,
      });
      try {
        const roster = { name: rosterName, isThirdParty: false };
        const { userId } = authState;
        const addedRoster = await addRoster(userId, roster);
        athletesDispatch({ type: "ADD_ROSTER", payload: addedRoster });
        console.log(addedRoster);
        setProgressMessage({
          isLoading: false,
          message: "Sucessfully Added Roster",
          isError: false,
        });
        setAddButtonDisabled(true);
        setBackButtonLabel("Exit");
        setTimeout(() => {
          hideModal();
        }, 1000);
      } catch (err) {
        if (err.response && err.response.status) {
          const status = err.response.status;
          if (status === 400) {
            const { data: message } = err.response;
            setProgressMessage({
              isLoading: false,
              message,
              isError: true,
            });
          } else {
            setProgressMessage({
              isLoading: false,
              message: "Failed adding roster, please try again",
              isError: true,
            });
          }
        }
      }
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Add Roster</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div>
            <TextField
              label={"Name"}
              value={rosterName}
              onChange={(e) => {
                e.preventDefault();
                setRosterName(e.target.value);
              }}
              helperText={validationMessage}
            />
          </div>
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              {backButtonLabel}
            </Button>
            <Button onClick={handleAddRoster} disabled={isAddButtonDisabled}>
              Add
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default AddRoster;
