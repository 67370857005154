import React, { useReducer, createContext } from "react";

const initialState = {
  isAuthenticated: null,
  userId: null,
  username: null,
  metricSetting: null,
  currentPageTitle: null,
};

const AppReducer = (state, action) => {
  switch (action.type) {
    case "AUTH_USER":
      const userId = action.payload;
      return { ...state, isAuthenticated: true, userId };
    case "UNAUTH_USER":
      return { ...state, isAuthenticated: false, userId: null };
    case "CONFIRM_USER":
      const username = action.payload;
      return { ...state, username };
    case "SET_CURRENT_PAGE_TITLE":
      const currentPageTitle = action.payload;
      return { ...state, currentPageTitle };
    case "SET_UNITS_SETTING":
      const metricSetting = action.payload;
      return { ...state, metricSetting };
    default:
      return state;
  }
};

const AppContext = createContext(initialState);

const AppProvider = ({ children }) => {
  const [appState, appDispatch] = useReducer(AppReducer, initialState);
  return (
    <AppContext.Provider value={{ appState, appDispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
