import { exportToExcelSpreadsheet } from "./exportExcel";

export const exportIndexReports = async (epicIndexReports) => {
  const epicIndexReportsDeepCopy = epicIndexReports.map((report) =>
    Object.assign({}, report)
  );
  epicIndexReportsDeepCopy.forEach((report) => {
    delete report.athleteId;
  });
  exportToExcelSpreadsheet(epicIndexReportsDeepCopy, "epicIndexReports");
};

export const exportTeamReports = async (teamReports) => {
  const teamReportsExport = [];
  teamReports.forEach((teamReport) => {
    const {
      date,
      rosterName,
      averageDashIndex,
      averageEpicIndex,
      averageProAgilityIndex,
      averageVerticalIndex,
      dashRange,
      proAgilityRange,
      verticalRange,
    } = teamReport;
    teamReportsExport.push({
      Date: date,
      Roster: rosterName,
      "Average Dash Index": averageDashIndex,
      "Minimum Dash Index": dashRange.min,
      "Maximum Dash Index": dashRange.max,
      "Average Pro Agility Index": averageProAgilityIndex,
      "Minimum Pro Agility Index": proAgilityRange.min,
      "Maximum Pro Agility Index": proAgilityRange.max,
      "Average Vertical Index": averageVerticalIndex,
      "Minimum Vertical Index": verticalRange.min,
      "Maximum Vertical Index": verticalRange.max,
      "Average Epic Index": averageEpicIndex,
    });
  });
  exportToExcelSpreadsheet(teamReportsExport, "TeamReports");
};
