const decimalNumberOrEmptyStringRegex = /^$|^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
const zipCodeOrEmptyStringRegex = /^$|^[0-9]{5}(?:-[0-9]{4})?$/;
const birthdayOrEmptyStringRegex = /^$|(0[1-9]|1[012])[/.](0[1-9]|[12][0-9]|3[01])[/.](19|20)[0-9]{2}/;
const gradYearOrEmptyStringRegex = /^$|^\d{4}$/;
const sexOrEmptyString = /^$|^(male|female)$/;

const athleteValidations = {
  firstName: {
    required: "First name is required",
  },
  middleName: {
    required: "Middle name is required",
  },
  lastName: {
    required: "Last name is required",
  },
  height: {
    pattern: {
      value: decimalNumberOrEmptyStringRegex,
      message: "Height needs to be a number",
    },
  },
  weight: {
    pattern: {
      value: decimalNumberOrEmptyStringRegex,
      message: "Weight needs to be a number",
    },
  },
  reach: {
    pattern: {
      value: decimalNumberOrEmptyStringRegex,
      message: "Weight needs to be a number",
    },
  },
  handSize: {
    pattern: {
      value: decimalNumberOrEmptyStringRegex,
      message: "Hand size needs to be a number",
    },
  },
  wingSpan: {
    pattern: {
      value: decimalNumberOrEmptyStringRegex,
      message: "Wingspan needs to be a number",
    },
  },
  shoeSize: {
    pattern: {
      value: decimalNumberOrEmptyStringRegex,
      message: "Shoe size needs to be a number",
    },
  },
  zipCode: {
    pattern: {
      value: zipCodeOrEmptyStringRegex,
      message: "Please enter a valid zipcode",
    },
  },
  graduationYear: {
    pattern: {
      value: gradYearOrEmptyStringRegex,
      message: "Please enter a valid year",
    },
  },
  birthDate: {
    pattern: {
      value: birthdayOrEmptyStringRegex,
      message: "Please enter a valid birthday (MM/DD/YYYY)",
    },
  },
  sex: {
    pattern: {
      value: sexOrEmptyString,
      message: `Sex needs to be "male" or "female"`
    }
  }
};

export default athleteValidations;
