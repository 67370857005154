import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const addWorksheet = (workbook, name, data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
}

export const exportToExcel = (name, workbook) => {
    const workbookBinary = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
    
      const buffer = new ArrayBuffer(workbookBinary.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i !== workbookBinary.length; ++i) {
        view[i] = workbookBinary.charCodeAt(i) & 0xff;
      }
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${name}.xlsx`
      );
}