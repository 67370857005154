import React, { createContext, useReducer } from "react";
import { determineReportSortOrder, determineTeamReportSortOrder } from "../utilities/sortOrder";

const initialState = {
  currentPage: 1,
  reportsPerPage: 20,
  totalReports: null,
  searchQuery: null,
  reports: null,
  reportTypes: [
    { id: 1, name: "Epic Index" },
    { id: 2, name: "Team" },
  ],
  activeReportType: 1,
  sortOrder: null,
  startDateFilter: null,
  endDateFilter: null,
  activeRosterFilter: -1,
  sortColumn: { path: "formattedTestingDate", order: "desc" },
  sort: "TestingDateDesc",
};

const INDIVIDUAL_ACTIVE_REPORT_TYPE = 1;

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIAL_STATE": {
      return initialState;
    }
    case "SET_REPORTS": {
      const { reports, totalReports } = action.payload;
      return {
        ...state,
        reports,
        totalReports,
      };
    }
    case "ADD_REPORT": {
      const report = action.payload;
      const reports = [...state.reports, report];
      return { ...state, reports };
    }
    case "DELETE_REPORT": {
      const reportId = action.payload;
      const reports = state.reports.filter((report) => report.id !== reportId);
      return { ...state, reports };
    }
    case "CHANGE_REPORT_TYPE": {
      const activeReportType = action.payload;
      let sort = null;
      let sortColumn = null;
      if (activeReportType === INDIVIDUAL_ACTIVE_REPORT_TYPE) {
        sortColumn = { path: "formattedTestingDate", order: "desc" };
        sort = determineReportSortOrder(sortColumn);
      } else {
        sortColumn = { path: "formattedDate", order: "desc" };
        sort = determineTeamReportSortOrder(sortColumn);
      }
      return { ...state, activeReportType, sort, sortColumn, reports: null };
    }
    case "SET_SORT_COLUMN": {
      const sortColumn = action.payload;
      let sort = null;
      const { activeReportType } = state;
      if (activeReportType === INDIVIDUAL_ACTIVE_REPORT_TYPE) {
        sort = determineReportSortOrder(sortColumn);
      } else {
        sort = determineTeamReportSortOrder(sortColumn);
      }
      return { ...state, sortColumn, sort };
    }
    case "SET_START_DATE_FILTER": {
      const startDateFilter = action.payload;
      return { ...state, startDateFilter };
    }
    case "SET_END_DATE_FILTER": {
      const endDateFilter = action.payload;
      return { ...state, endDateFilter };
    }
    case "SET_SEARCH_QUERY":
      const searchQuery = action.payload;
      return { ...state, searchQuery };
    case "SET_ACTIVE_ROSTER_FILTER": {
      const activeRosterFilter = action.payload;
      return { ...state, activeRosterFilter };
    }
    case "CHANGE_REPORTS_PER_PAGE":
      const reportsPerPage = action.payload;
      return { ...state, reportsPerPage };
    case "CHANGE_CURRENT_PAGE":
      const pageNumber = action.payload;
      return { ...state, currentPage: pageNumber };
    default:
      return state;
  }
};

const ReportsContext = createContext(initialState);

const ReportsProvider = ({ children }) => {
  const [reportsState, reportsDispatch] = useReducer(reducer, initialState);

  return (
    <ReportsContext.Provider
      value={{
        reportsState,
        reportsDispatch,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export { ReportsContext, ReportsProvider };
