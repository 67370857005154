import React, { createContext, useState } from "react";

const ModalContext = createContext([]);

const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const showModal = (type, props) => {
    setModals([...modals, { type, props }]);
  };
  const hideModal = () => {
    const updatedModals = [...modals];
    updatedModals.pop();
    setModals([...updatedModals]);
  };

  const replaceModal = (type, props) => {
    const updatedModals = [...modals];
    updatedModals.pop();
    updatedModals.push({ type, props });
    setModals([...updatedModals]);
  };

  return (
    <ModalContext.Provider value={{ modals, showModal, hideModal, replaceModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
