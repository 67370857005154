import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/Button";
import ProgressMessage from "../../components/progressmessage/ProgressMessage";
import TextField from "../../containers/textfield/TextField";
import validateChangePassword from "./changePasswordValidation";

const ChangePasswordSuccess = () => {
  const history = useHistory();
  return (
    <>
      <ProgressMessage
        isLoading={false}
        message={"Successfully Changed Password!"}
        isError={false}
      />
      <Button onClick={() => history.push("/login")} fullWidth>LOGIN</Button>
    </>
  );
};

const ChangePassword = ({ location }) => {
  const [changePasswordCode, setChangePasswordCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [progressMessage, setProgressMessage] = useState(null);
  const [didChangePassword, setDidChangePassword] = useState(false);
  const [validationMessages, setValidationMessages] = useState({
    changePasswordCode: null,
    password: null,
    confirmPassword: null,
  });
  const history = useHistory();
  const email = location.state.email;

  useEffect(() => {
    if (!email) {
      history.push("/");
    }
  }, [email, history]);

  const handleChangePassword = async () => {
    try {
      const validationErrors = validateChangePassword(
        changePasswordCode,
        password,
        confirmPassword
      );
      if (validationErrors) {
        const {
          changePasswordCode,
          password,
          confirmPassword,
        } = validationErrors;
        setValidationMessages({
          changePasswordCode,
          password,
          confirmPassword,
        });
        return;
      }
      setProgressMessage({
        isLoading: true,
        message: "Changing password...",
        isError: false,
      });
      await Auth.forgotPasswordSubmit(email, changePasswordCode, password);
      setDidChangePassword(true);
    } catch (err) {
      setProgressMessage({ isLoading: false, message: err.message, isError: true });
    }
  };

  if (didChangePassword) {
    return <ChangePasswordSuccess />;
  }

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <>
      {progressMessageComponent}
      <TextField
        label={"Code"}
        value={changePasswordCode}
        onChange={(e) => {
          e.preventDefault();
          setChangePasswordCode(e.target.value);
        }}
        helperText={validationMessages.changePasswordCode}
      />
      <TextField
        label={"Password"}
        value={password}
        inputType={'password'}
        onChange={(e) => {
          e.preventDefault();
          setPassword(e.target.value);
        }}
        helperText={validationMessages.password}
      />
      <TextField
        label={"Confirm Password"}
        value={confirmPassword}
        inputType={'password'}
        onChange={(e) => {
          e.preventDefault();
          setConfirmPassword(e.target.value);
        }}
        helperText={validationMessages.confirmPassword}
      />
      <Button onClick={handleChangePassword} fullWidth>SIGN UP</Button>
    </>
  );
};

export default ChangePassword;
