import React, { useState, useContext, useEffect } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHeader,
  ModalCardTitle,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { AuthContext } from "../../../../contexts/AuthContext";
import TextField from "../../../textfield/TextField";
import Button from "../../../../components/button/Button";
import { AccountContext } from "../../../../contexts/AccountContext";
import styles from "./EditAccount.module.scss";
import { updateUserProfileService } from "../../../../services/coachService";

const accountDetails = [
  { label: "First Name", property: "firstName" },
  { label: "Last Name", property: "lastName" },
  { label: "Address", property: "address" },
  { label: "City", property: "city" },
  { label: "Zip", property: "zip" },
];

const EditAccount = ({ athlete }) => {
  const { hideModal } = useContext(ModalContext);
  const [progressMessage, setProgressMessage] = useState(null);
  const { state: authState } = useContext(AuthContext);
  const { accountInfo, updateAccountInfo } = useContext(AccountContext);
  const [accountInput, setAccountInput] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    zip: "",
  });

  useEffect(() => {
    const { firstName, lastName, address, city, zip } = accountInfo;
    setAccountInput({
      firstName,
      lastName,
      address,
      city,
      zip,
    });
  }, [accountInfo]);

  const handleEditAccountDetails = async () => {
    setProgressMessage({
      isLoading: true,
      message: "Editing Profile...",
      isError: false,
    });

    try {
      const { userId } = authState;
      await updateUserProfileService(userId, accountInput);
      updateAccountInfo(accountInput)
      setProgressMessage({
        isLoading: false,
        message: "Successfully updated account settings",
        isError: false,
      });
    } catch (err) {
      setProgressMessage({
        isLoading: false,
        message: "Failed editing account settings, please try again",
        isError: true,
      });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }
  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Edit Account Details</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          <div>
            {accountDetails.map((field) => {
              const { label, property } = field;
              return (
                <TextField
                  label={label}
                  required={false}
                  value={accountInput[property]}
                  onChange={(e) => {
                    e.preventDefault();
                    setAccountInput({
                      ...accountInput,
                      [property]: e.target.value,
                    });
                  }}
                />
              );
            })}
          </div>
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              Cancel
            </Button>
            <Button onClick={handleEditAccountDetails}>Modify</Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default EditAccount;
