import React from "react";
import SideNavbar from "../../containers/sidenavbar/SideNavbar";
import { Redirect, Switch, Route } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import Athletes from "../../pages/athletes/Athletes";
import Reports from "../../pages/reports/Reports";
import Results from "../../pages/results/Results";
import { Columns, Column } from "bloomer";
import TopNavbar from "../../containers/topnavbar/TopNavbar";
import Account from "../../pages/account/Account";

const Dashboard = () => {
  return (
    <>
      <div className={styles.sidePanel}>
        <SideNavbar />
      </div>
      <div className={styles.contentPanel}>
        <Columns>
          <Column isSize="full">
            <TopNavbar/>
          </Column>
        </Columns>
        <Switch>
          <Route path="/dashboard/athletes" exact component={Athletes} />
          <Route path="/dashboard/reports" exact component={Reports} />
          <Route path="/dashboard/results" exact component={Results} />
          <Route path="/dashboard/account" exact component={Account} />
          <Redirect from="/dashboard" exact to="/dashboard/athletes" />
        </Switch>
      </div>
    </>
  );
};

export default Dashboard;
