import React from "react";
import { decimalNumberPattern } from "../../../../../common/patterns/patterns";
import Event from "./Event";

const fields = [
  { type: "input", label: "Lifting", property: "lift" },
  { type: "input", label: "Weight", property: "weight" },
  { type: "input", label: "Reps", property: "reps" },
];

const validations = {
  lift: {
    required: "The type of lift is required",
  },
  weight: {
    pattern: {
      value: decimalNumberPattern,
      message: "The amount of weight needs to be a number",
    },
  },
  reps: {
    pattern: {
      value: decimalNumberPattern,
      message: "The number of reps needs to be a number",
    },
  },
};

const initialValues = { lift: "", weight: "0.0", reps: "0.0" };

const isValidLift = (values) => {
  const weight = parseFloat(values.weight);
  const reps = parseFloat(values.reps);

  if (weight === 0 || reps === 0) {
    return {
      isValid: false,
      message: "Weight and/or reps cannot be 0",
      eventFields: null,
    };
  }

  const eventFields = {
    type: "Lifting",
    lift: values.lift,
    weight,
    reps,
  };
  return { isValid: true, message: null, eventFields };
};

const Lifting = ({ athleteId, isMetric }) => {
  return (
    <Event
      title={"Add Lifting"}
      athleteId={athleteId}
      isMetric={isMetric}
      fields={fields}
      initialValues={initialValues}
      validations={validations}
      isValidEvent={isValidLift}
    />
  );
};

export default Lifting;
