import { Auth } from "aws-amplify";
import React, { useState } from "react";
import Button from "../../components/button/Button";
import TextField from "../../containers/textfield/TextField";
import validateEmail from "./forgotPasswordValidation";
import { useHistory } from "react-router-dom";
import ProgressMessage from "../../components/progressmessage/ProgressMessage";

import styles from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState(null);
  const [progressMessage, setProgressMessage] = useState(null);
  const history = useHistory();

  const handleSendForgotPasswordCode = async () => {
    try {
      const emailValidationMessage = validateEmail(email);
      if (emailValidationMessage) {
        setEmailErrorMsg(emailValidationMessage);
        return;
      }
      setProgressMessage({
        isLoading: true,
        message: "Sending code...",
        isError: false,
      });
      await Auth.forgotPassword(email);
      history.push("/changepassword", { email });
    } catch (err) {
      console.log(err)
      setProgressMessage({
        isLoading: false,
        message: err.message,
        isError: true,
      });
    }
  };

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <>
      {progressMessageComponent}
      <div className={styles.forgotPasswordMessage}>
        Please enter your email
      </div>
      <TextField
        label={"Email"}
        value={email}
        onChange={(e) => {
          e.preventDefault();
          setEmail(e.target.value);
        }}
        helperText={emailErrorMsg}
      />
      <Button onClick={handleSendForgotPasswordCode} fullWidth>SUBMIT</Button>
    </>
  );
};

export default ForgotPassword;
