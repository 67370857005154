import React, { useContext } from "react";
import Detail from "../../../../components/detail/Detail";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardFooter,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import Button from "../../../../components/button/Button";
import styles from "./ResultDetail.module.scss";
import getResultDetails from "./resultDetailUtil";
import { AuthContext } from "../../../../contexts/AuthContext";
import { getAthlete } from "../../../../services/athleteService";

const ResultDetail = ({ result }) => {
  const { replaceModal, hideModal } = useContext(ModalContext);
  const resultDetails = getResultDetails(result);
  const { state: authState } = useContext(AuthContext);

  const gotoAthleteBio = async () => {
    const { userId } = authState;
    const { athlete } = result;
    try {
      const fetchedAthlete = await getAthlete(userId, athlete.id);
      replaceModal("AthleteDetail", { athlete: fetchedAthlete });
    } catch (err) {
    }
  };

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Result Detail</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          {resultDetails.map((resultDetail) => {
            console.log(resultDetail);
            const { label, value } = resultDetail;
            return <Detail label={label} value={value} />;
          })}
        </ModalCardBody>
        <ModalCardFooter>
          <Button onClick={gotoAthleteBio}>Athlete</Button>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              Back
            </Button>
            <Button
              type={"delete"}
              onClick={() =>
                replaceModal("DeleteResult", { resultId: result.id })
              }
            >
              Delete Result
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default ResultDetail;
