import React, { useContext } from "react";
import Button from "../../components/button/Button";
import Detail from "../../components/detail/Detail";
import { AccountContext } from "../../contexts/AccountContext";
import { ModalContext } from "../../contexts/ModalContext";

import styles from "./ProfileOverview.module.scss";

const profileDetails = [
  { label: "Email", property: "email" },
  { label: "First Name", property: "firstName" },
  { label: "Last Name", property: "lastName" },
  { label: "Address", property: "address" },
  { label: "City", property: "city" },
  { label: "Zip", property: "zip" },
];

const ProfileOverview = () => {
  const { accountInfo } = useContext(AccountContext);
  const { showModal } = useContext(ModalContext);
  console.log(accountInfo)
  return (
    <>
      {profileDetails.map((profileDetail) => {
        const { label, property } = profileDetail;
        return <Detail key={label} label={label} value={accountInfo[property]} />;
      })}
      <div className={styles.buttonWrapper}>
        <Button onClick={() => showModal('EditAccount')}>Edit Profile</Button>
      </div>
    </>
  );
};

export default ProfileOverview;
