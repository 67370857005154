import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarItem,
  NavbarMenu,
} from "bloomer";
import styles from "./NewUserNavbar.module.scss";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Field } from "bloomer/lib/elements/Form/Field/Field";
import { Control } from "bloomer/lib/elements/Form/Control";
import classNames from "classnames";

const NewUserNavbar = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  const menu = [
    { path: "/login", icon: "person", label: "LOGIN" },
    { path: "/signup", icon: "person_add", label: "SIGNUP" },
  ];

  return (
    <Navbar className={styles.newUserNavbar}>
      <NavbarBrand>
        <NavbarItem>
          <img className={styles.logo} alt="logo" src={logo} />
        </NavbarItem>
        <NavbarBurger
          className={styles.hamburgerMenu}
          isActive={isMobileMenuActive}
          onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
        />
      </NavbarBrand>
      <NavbarMenu
        className={styles.mobileNavbarMenu}
        isActive={isMobileMenuActive}
        onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
      >
        {menu.map((menuItem) => {
          const { path, label } = menuItem;
          return (
            <NavbarItem isHidden="desktop" key={label}>
              <Link className={styles.mobileNavbarMenuItem} to={path}>
                {label}
              </Link>
            </NavbarItem>
          );
        })}
      </NavbarMenu>

      <NavbarEnd>
        <NavbarItem isHidden="touch">
          <Field isGrouped>
            <Control>
              {menu.map((menuItem) => {
                const { path, icon, label } = menuItem;
                return (
                  <Link
                    to={path}
                    className={classNames(
                      "button is-primary",
                      styles.navbarMenuLink
                    )}
                  >
                    <i className="material-icons">{icon}</i>
                    <span className={styles.navbarMenuTitle}>{label}</span>
                  </Link>
                );
              })}
            </Control>
          </Field>
        </NavbarItem>
      </NavbarEnd>
    </Navbar>
  );
};

export default NewUserNavbar;
