import React, { useEffect, useState, useContext } from "react";
import {
  Delete,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardHeader,
  ModalCardTitle,
  Select,
} from "bloomer";
import { ModalContext } from "../../../../contexts/ModalContext";
import styles from "./AddTeamReport.module.scss";
import ProgressMessage from "../../../../components/progressmessage/ProgressMessage";
import { ModalCardFooter } from "bloomer/lib/components/Modal/Card/ModalCardFooter";
import Button from "../../../../components/button/Button";
import {
  getRosters,
} from "../../../../services/rosterService";
import { AuthContext } from "../../../../contexts/AuthContext";
import FetchMessage from "../../../../components/fetchMessage/FetchMessage";
import { generateTeamReportService, getPaginatedTeamReports } from "../../../../services/reportsService";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import environment from "../../../../environment/environment";

const { API_URL } = environment;

const AddTeamReport = () => {
  const [progressMessage, setProgressMessage] = useState(null);
  const { hideModal } = useContext(ModalContext);
  const { state: authState } = useContext(AuthContext);
  const { reportsState, reportsDispatch } = useContext(ReportsContext);
  const [rosters, setRosters] = useState(null);
  const [currentRoster, setCurrentRoster] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const { userId } = authState;
  useEffect(() => {
    const fetchRosters = async () => {
      let rosters = await getRosters(userId);
      rosters = rosters.filter((roster) => roster.id !== -1);
      if (rosters.length > 0) {
        setRosters(rosters);
        setCurrentRoster(rosters[0].id);
        setIsFetching(false);
      }
    };
    fetchRosters();
  }, [userId]);

  const handleGenerateTeamReport = async () => {
    try {
      setProgressMessage({
        isLoading: true,
        message: "Generating Team Report",
        isError: false,
      });
      const teamReport = await generateTeamReportService(userId, currentRoster);
      reportsDispatch({ type: "ADD_REPORT", payload: teamReport });
      window.open(`${API_URL}/teamepicindex/${userId}/${teamReport.id}/render`);
      setProgressMessage({
        isLoading: false,
        message: "Sucessfully Generated Team Report",
        isError: false,
      });

      const {
        currentPage,
        reportsPerPage,
        searchQuery,
        sort,
        activeRosterFilter,
        startDateFilter,
        endDateFilter,
      } = reportsState;

      const paginatedReportData = await getPaginatedTeamReports(
        userId,
        currentPage,
        reportsPerPage,
        activeRosterFilter,
        searchQuery,
        sort,
        startDateFilter,
        endDateFilter
      );
      const { reports, totalReports } = paginatedReportData;
      reportsDispatch({
        type: "SET_REPORTS",
        payload: { reports, totalReports },
      });

    } catch (err) {
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 400) {
          const { data: message } = err.response;
          setProgressMessage({
            isLoading: false,
            message,
            isError: true,
          });
        } else {
          setProgressMessage({
            isLoading: false,
            message: "Failed Generating Team Report",
            isError: true,
          });
        }
      }
    }
  };

  let modalContents = null;
  if (isFetching && !rosters) {
    modalContents = <FetchMessage message={"Fetching Rosters"} />;
  }
  if (!isFetching && !rosters) {
    modalContents = "Cannot fetch rosters, please try again";
  }
  if (!isFetching && rosters && currentRoster) {
    modalContents = (
      <div className={styles.centeredWrapper}>
        <div className={styles.rosterMessageWrapper}>
          Please choose a roster
        </div>
        <div>
          <div>
            <Select
              onChange={(e) => {
                e.preventDefault();
                setCurrentRoster(e.target.value);
              }}
            >
              {rosters.map((roster) => {
                return (
                  <option key={roster.id} value={roster.id}>
                    {roster.name}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
    );
  }

  let progressMessageComponent = null;
  if (progressMessage) {
    const { isLoading, message, isError } = progressMessage;
    progressMessageComponent = (
      <ProgressMessage
        isLoading={isLoading}
        message={message}
        isError={isError}
      />
    );
  }

  return (
    <Modal isActive>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Add Team Report</ModalCardTitle>
          <Delete
            onClick={() => {
              hideModal();
            }}
          />
        </ModalCardHeader>
        <ModalCardBody>
          {modalContents}
          {progressMessageComponent}
        </ModalCardBody>
        <ModalCardFooter>
          <div className={styles.buttonWrapper}>
            <Button type={"back"} onClick={() => hideModal()}>
              Cancel
            </Button>
            <Button onClick={handleGenerateTeamReport}>
              Generate Team Report
            </Button>
          </div>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default AddTeamReport;
